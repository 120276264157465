import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { Button, Modal } from "react-bootstrap";
import { NotificationManager } from 'react-notifications'
import { Session } from "../../Session";
import { MemberPopup } from '../../components/MemberPopup';

export const MyCompany = () => {
    const [showMemberPopup, setShowMemberPopup] = useState(false);
    const [members, setMembers] = useState<any[]>([])
    const [selectedMember, setSelectedMember] = useState<any>(null)

    // useEffect(() => {
    //     whitespace(Query.GET, '/api/user/myDetails').then(response1 => {
    //         setMyEmail( response1.data.username );
    //         const companyId = response1.data.companyId;
    //         whitespace(Query.GET, `/api/documents/Corporate-${companyId}`).then(response2 => {
    //             setCorporate( response2.data );
    //         });
    //     })
    // }, [])

    const fetchMembers = ( ) => {
        const admins = Session.corporate.admins;
        if( !admins.includes( Session.userDetails.username ) ) {
            NotificationManager.error( "This function is restricted to administrators");
            setMembers( [] );
            return;
        }
        const array : any[] = [];
        Session.corporate.members.map( ( c ) => { 
            if( c.isOffPlatform != true ) {
                const c2 : any = c;
                c2.teamsCSV = ( c.teams.length > 2 ? `${c.teams.length} teams` : c.teams.join( ', ') ); 
                c2.isAdmin = ( admins.indexOf( c.email ) >= 0 );
                array.push( c2 );
            }
        });
        setMembers( array );
    }

    const makeCSVLine = ( parts ) => {
        return parts.map( p => { return( p.includes( "," ) ? '"' + p + '"' : p )}).join( ",") + "\n";
    }

    const downloadMembers = () => {
        const content: [string] = [ makeCSVLine( [ "Email", "Name", "State", "Reporting", "Admin", "Teams"] ) ]
        members.map( ( c ) => { content.push( makeCSVLine( [ c.email, c.name, c.currentState,
            c.allowReporting ? "Y" : "", c.isAdmin ? "Y" : "",
            c.teams.join( ', ') ]))})
        const blob = new Blob(content, {type: 'text/csv'});
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `${Session.corporate.companyId}-Members.csv`
        link.click()
    }

    const MembersColumns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            width: '30%'
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
            width: '30%'
        },
        {
            name: 'State',
            selector: 'currentState',
            sortable: true,
            width: '10%'
        },
        {
            name: 'Teams',
            selector: 'teamsCSV',
            sortable: false,
            width: '30%'
        }
    ];

    const rowClicked = ( member ) => {
        console.log( member );
        setSelectedMember( member );
        setShowMemberPopup( member != null );
    }

    return (
        <>
        <Button onClick={fetchMembers}>Members</Button>
        {selectedMember &&
        <MemberPopup selectedMember ={selectedMember} setSelectedMember={setSelectedMember} showMemberPopup={showMemberPopup} />}
        {members.length > 0 &&
        <>
        <DataTable
                    columns={MembersColumns}
                    data={members}
                    defaultSortField="Name"
                    defaultSortAsc={true}
                    onRowClicked={rowClicked}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 50, 100, 500]}
                    persistTableHead
                    className="RiskGridSingleLine"
                /> 
                <Button onClick={downloadMembers}>Download CSV</Button>
                </>
        }
        </>
    )
}