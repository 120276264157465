import React from 'react'
import { Titlebar } from './Titlebar'

export const Page = ({ module, refresh }) => {
    return (
        <div className='page-wrapper'>
            <Titlebar module={module} />
            <div className="Page">
                <module.component refresh={refresh} />
            </div>
        </div>
    )
}