export const McGillDetails : any = 
{
    name: "Gail Broker",
    partyIdentifiers: [
      {
        id: "123987456",
        type: "DUNS"
      },
      {
        id: "8899",
        type: "LLOYDS"
      }
    ],
    partyAddresses: [
      {
        zipCode: "25678",
        city: "Newark",
        location: {
          ISOCountryShortName: "US",
          countrySubDivisionCode: "US-NJ",
          countrySubDivisionName: "New Jersey"
        },
        streetNoAndStreet: "51 11th Avenue"
      }
    ]
}