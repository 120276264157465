
export class SummaryItem {
	ob: any = {};
	updatedAt = "";
	rootID = "";
	insuredName = "";
	umr = "";
	rwplacingCount = 0;
	constructor( ob : any ) {
		this.ob = ob;
		this.rootID = ob.rootID || "";
		this.updatedAt = ob.updatedAt || "";
		this.insuredName = "";
		this.umr = "";
		this.rwplacingCount = 0;
		ob.docs.forEach( doc => {
			if( doc.type == "RWPlacing") {
				this.rwplacingCount += 1;
				this.umr = this.umr || doc.umr;
				this.insuredName = this.insuredName || doc.insuredName;
			}
		});
	}
}