/**
 * MRC Helper Utils
 */
  
export const interpolatePlaceholders = ( lineItem, separator = ' ' ) => {
    let textString = lineItem.elements.map(element => element.text).join(separator)
    lineItem.placeholders.map(placeholder => {
        textString = textString.replace(`[[${placeholder.name}]]`, placeholder.value)
    })
    return textString
}

export const getDefinedData = ( risk, mrcHeading, tag ) => {
    const lineitem = risk.MRCContract.lineItems.filter(lineItem => lineItem.mrcHeading === mrcHeading)[0];
    if( !lineitem ) {
        return "";
    }
    const placeholder = lineitem.placeholders.filter( ph => ph.tags && ph.tags.indexOf( tag ) >= 0 )[0];
    if( !placeholder ) {
        return "";
    }
    return placeholder.value;
}

export const getMRCItemText = (risk, mrcHeading, separator = ' ') => {
    try {
        const lineitem = risk.MRCContract.lineItems.filter(lineItem => lineItem.mrcHeading === mrcHeading)[0];
        return interpolatePlaceholders( lineitem, separator );
    } catch {
        return ''
    }
}

export const mrcItemToEOC = (
    risk: Record<string,any>, 
    EOC: Record<string,any>, 
    mrcHeading: string, 
    replaceString: string
): Record<string,any> => {
    try {
        return JSONSR(EOC, replaceString, getMRCItemText(risk, mrcHeading))
    } catch {
        return JSONSR(EOC, replaceString, '')
    }
}

export const JSONSR = (objJSON, search, replace) => {
    const objString = JSON.stringify(objJSON)
    const escaped = replace.replaceAll( '"', '\\"').replaceAll( "\r", "").replaceAll( "\n", "\\n");
    // if( escaped != replace ) {
    //     console.log( replace );
    //     console.log( escaped );
    // }
    const updatedObjString = objString.replace(search, escaped)
    return JSON.parse(updatedObjString)
}