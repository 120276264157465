import React from 'react'
import { getSessionDetails } from '../helpers/auth'

export const Titlebar = ({ module }) => {
    const session = getSessionDetails()
    return (
        <div className="Titlebar">
            {module.header}
            <div className="Session-info">
                👤 {session.account.userName}
            </div>
        </div>
    )
}