import { propTypes } from "react-bootstrap/esm/Image"

export const CDRXMRCViewer = ( props : any ) => {
    return (
        <>
        { props.xmrc && 
            <>
            <div className='MySubTitle'>UMR</div>
            <div>{props.xmrc.control.umr}</div>
            <div className='MySubTitle'>Insured</div>
            <div>{props.xmrc.control.insuredName}</div>
            <div className='MySubTitle'>Status</div>
            <div>{props.xmrc.control.status}</div>
            <div className='MySubTitle'>IC Number</div>
            <div className='MyTinyText'>{props.xmrc.platformReferences.RiskID}</div>
            <div className='MySubTitle'>Revision</div>
            <div className='MyTinyText'>{props.xmrc.platformReferences.Revision}</div>
            </>
        }
        { !props.xmrc && 
            <div>XMRC not selected</div>
        }
        </>
    )
}

export const CDRExtractViewer = ( props : any ) => {
    return (
        <>
        {
            !props.extract && <div>Data not extracted</div> 
            
        }
        {
            props.extract &&
            <>
            <div className='MySubTitle'>Method of Placement</div>
            <div>{props.extract.methodOfPlacement}</div>
            <div className='MySubTitle'>Intermediaries</div>
            {props.extract.intermediaries && props.extract.intermediaries.map((itm, idx) => <div className='MyTinyText' key={idx}>{itm.party.name} {itm.type}</div> ) }
            <div className='MySubTitle'>Clauses</div>
            {props.extract.clauses && props.extract.clauses.map((itm,idx) => <div className='MyTinyText' key={idx}>{itm.referenceNumber} {itm.clauseStatus}</div>)}
            </>
        }
        </>
    )
}

export const CDRResponseViewer = ( props : any ) => {
    const renderLinkMaybe = ( ob ) => {
        if( !ob || !ob.status || ob.status == "PENDING" ) {
            return ( <div>{ob?.status || "None"}</div> )
        } else {
            return ( 
                <>
                <div><span className="MyLink" onClick={() => props.clickHandler( ob )}>{ob.status}</span></div>
                { ob.message && <div className="MyTinyText">{ob.message}</div>}
                { ob.errors && <>
                    <div>{ob.errors.length} {ob.errors.length> 1 ? "Errors" : "Error"}</div>
                    <ol>
                    {ob.errors.map( (itm) => <li className="MyTinyText ForceNumericLI">{itm.message || "No Message"}</li> )}
                    </ol>
                    </>
                }
                 
                </> 
            )
        }

    }

    return (
        <>
        {
            !props.response && <div>CDR Service not called</div>
        }
        {
            props.response && 
            <>
            <div className='MySubTitle'>CDR ID</div>
            <div className='MyTinyText'>{props.response.cdrId}</div>

            <div className='MySubTitle'>Tax Calculator</div><div>{renderLinkMaybe(props.response.services.taxcalculator)}</div>
            <div className='MySubTitle'>Risk Locations</div><div>{renderLinkMaybe(props.response.services.riskLocations)}</div>
            <div className='MySubTitle'>Licence Status Check</div><div>{renderLinkMaybe(props.response.services.licenceStatusCheck)}</div>
            <div className='MySubTitle'>IDV</div><div>{renderLinkMaybe(props.response.services.idv)}</div>
            <div className='MySubTitle'>Sanctions</div><div>{renderLinkMaybe(props.response.services.sanctions)}</div>
            <div className='MySubTitle'>Clause Checker</div><div>{renderLinkMaybe(props.response.services.clauseChecker ) }</div>
            <div className='MySubTitle'>Reg Licence Checker</div><div>{renderLinkMaybe(props.response.services.reglicenceChecker)}</div>
            </>
        }
        </>
    )
}

export const CDRTaxViewer = ( props: any ) => {
    return (
        <>
        {
            !props.response && <div>Tax Service not called</div>
        }
        {
            props.response && 
            <>
            <div className='TaxMainTitle'>Calculated Taxes</div>
            {props.response.calculatedTaxes && props.response.calculatedTaxes.map((itm,idx) =>
                <>
                {
                    itm.applicableTaxes.map((jtm,jdx) =>
                        <>
                        <div className='TaxSubTitle'>{jtm.taxType}</div>
                        <div className='MyTinyText' key={`Basis${jdx}`}>Basis Method: {jtm.taxBasisMethod}</div>
                        <div className='MyTinyText' key={`Rate${jdx}`}>Rate: {jtm.taxRate.taxRate} {jtm.taxRate.taxApplicationType}</div>
                        <div className='MyTinyText' key={`Code${jdx}`}>Tax Code: {jtm.taxCode}</div>
                        </>
                    )
                }
                </>
            )}
            <div className='TaxMainTitle'>Aggregated Taxes</div>
            {props.response.aggregatedTaxes && props.response.aggregatedTaxes.map((itm,idx) => 
                <>
                <div className='TaxSubTitle'>{itm.taxType}</div>
                <div className='MyTinyText' key={`Code${idx}`}>Tax Code: {itm.taxCode}</div>
                <div className='MyTinyText' key={`Amount${idx}`}>Amount: {itm.taxAmount}</div>
                </>
            )}
            </>
        }
        </>
    )
}