export const PresetEndpoints = [
    { url: '/api/user/myDetails', description: 'Details of the user including teams' },
    { url: '/api/user/corporate', description: 'Details of the Corporate for the user' },
    { url: '/api/shared/corporate', description: 'All corporates' },
    { url: '/api/documents/$IC', description: 'Fetch any document' },
    
    { url: '/api/user/colleagues', description: 'List of colleagues at the same corporate' },
    { url: '/api/summary', description: 'Summary of sixty risks' },
    { url: '/api/risks', description: 'List of all risks' },
    { url: '/api/risks/$IC', description: 'Detail of one risk' },
    
    { url: '/api/risks/$IC/getExtendedMRC', description: 'Extended MRC to include written and sgined lines' },

    { url: '/api/lines/$IC/combinedSets', description: 'Written and Signed lines for a risk' },
    { url: '/api/risks/$IC/requestWrittenLineSets', description: 'POST Show Firm Order to Carrier', 
        comments: [
            "Pick a ::FO and put its _rev revision from /api/risks/$IC into REV",
            "Set the companyId and teamId to the target company"
        ],
        payload : { 
        contents: [{
            "teamId": "MOTOR",
            "companyId": "WHITESAILS",
            "sections": [{
                "min": 10,
                "max": 20,
                "stamps": [],
                "leader": false
            }]
        }],
        _rev: "[[REV]]"        
    }},
    { url: '/api/activities/$IC', description: 'List of activities on a risk' },
    { url: '/api/activities/$IC/full', description: 'Full details of activities on a risk' },
    
    { url: '/api/risks/$IC/lineitem/$MRCHEADING', description: 'One line item by index or heading' },
    { url: '/api/v22.04/data/$IC', description: 'Defined Data GET' },
    { url: '/api/v22.04/data/$IC/tagset/ACORDGPM', description: 'Defined Data GET tagset ACORDGPM' },
    { url: '/api/v22.04/data/$IC/', description: 'Defined Data POST',
        comments:[
            "Grab GET output","remove the defined data items you can't or don't want to change", "tweak the remainder", "and POST it back"
        ],
        payload: {
            definedData: [],
            multiSectionDefinition: [],
            metadata: {}
        }
    },
    { url: '/api/v22.04/data/$IC/verify', description: 'Verify the input for Defined Data POST',
        comments:[
            "Grab GET output","remove the defined data items you can't or don't want to change", "tweak the remainder", "and POST it back"
        ],
        payload: {
            definedData: [],
            multiSectionDefinition: [],
            metadata: {}
        }
    },
    {
        url: '/api/risks/$IC/changeDraftNowQuoteInPreparation', description: 'Uwr sets up a quote in preparation from a request', payload: {},
        comments: [ "IC should be ICxxxx::QR1 etc"] 
    },
    { url: '/api/documents/getLineGuidanceForRoot/$IC', description: 'Line guidance document(s) for a RootID'},
    { url: '/api/documents/$IC::MS', description: 'Multi-section Document for a RootID (broker only)'},
    {
        url: '/api/risks/$IC/changeDraftNowQuoteInPreparation', description: 'Uwr sets up a quote in preparation from a request', payload: {},
        comments: [ "IC should be ICxxxx::QR1 etc"] 
    },
    {
        url: '/mireport/prepare', description: 'Prepare the MI Report', payload: {}, comments: [ "Run this first, then /mireport/fetch","It may take a while to execute..."]
    },
    {
        url: '/mireport/fetch', description: "Fetch the prepared MI Report", payload: {filename: "MIREPORT.csv", layout: "tall", format: "csv"} 
    }
]