import objectPath from 'object-path';

export class TaxPayload {
    insuranceContractType = "";
    premium = 0.0;
    cover?: TaxCover;
    insuredItems: TaxInsuredItem[] = [];
    insured?: TaxInsured;
    startOfInsurancePeriod = "";
    endOfInsurancePeriod = "";
    intermediaries: TaxIntermediary[] = [];
    dateContractEnteredInto = "";
    dateObjectCreated?: string;

    static makeExample(): TaxPayload {
        const result = new TaxPayload();

        result.insuranceContractType = "INSURANCE";
        result.premium = 835.00;
        result.cover = TaxCover.makeExample();
        result.insuredItems.push( TaxInsuredItem.makeExample() );
        result.insured = TaxInsured.makeExample();
    
        result.startOfInsurancePeriod = "2021-01-01 00:00:00";
        result.endOfInsurancePeriod = "2021-12-31 23:59:59";
        result.dateContractEnteredInto = "2021-01-02 00:00:00";
        result.intermediaries.push( TaxIntermediary.makeExample("LLOYDS_BROKER") );
        result.intermediaries.push( TaxIntermediary.makeExample("RETAIL_BROKER") );
        result.intermediaries.push( TaxIntermediary.makeExample("SURPLUS_LINES_BROKER") );
        return result;
    }

    static buildFromCDRResponse( input : any ) : TaxPayload {
        const now = Date();

        const result = new TaxPayload();    // x
        result.insuranceContractType = input.insuranceContractType || '';
        result.startOfInsurancePeriod = TaxPayload.tidyDate( objectPath.get( input, "startOfInsurancePeriod") );
        result.endOfInsurancePeriod = TaxPayload.tidyDate( objectPath.get( input, "endOfInsurancePeriod") );
        result.dateContractEnteredInto = TaxPayload.tidyDate( objectPath.get( input, "dateContractEnteredInto") );

        result.premium = parseFloat( objectPath.get( input, "contractPremiums.0.premiumAmount" ) || "0" );
        result.cover = TaxCover.buildFromCDRResponse( objectPath.get( input, "contractCovers.0") );
        result.insured = TaxInsured.buildFromCDRResponse( objectPath.get( input, "insured.0") );

        let array = objectPath.get( input, "insuredItems" );
        for( let i = 0, len = array.length; i < len; i++ ) {
            result.insuredItems.push( TaxInsuredItem.buildFromCDRResponse( objectPath.get( array[i] ) ) );
        }
        array = objectPath.get( input, "intermediaries" );
        for( let i = 0, len = array.length; i < len; i++ ) {
            result.intermediaries.push( TaxIntermediary.buildFromCDRResponse( objectPath.get( array[i] ) ) );
        }
        
        result.dateObjectCreated = now;
        return result;
    }

    static tidyDate( input: string ) : string {
        let result = input ? input.replace( 'T', ' ' ).replace( 'Z', '' ) : '';
        if( result.length > 19 )
            result = result.substr(0, 19);
        return result;
    }
}
export class TaxInsured {
    insuredType = "";
    insuredName = "";
    insuredAddress? : TaxAddress;
    static makeExample(): TaxInsured {
        const result = new TaxInsured();
        result.insuredName = "Magpie Holdings Inc";
        result.insuredType = "COMMERCIAL";
        result.insuredAddress = TaxAddress.makeExample();
        return result;
    }
    static buildFromCDRResponse( input: any ): TaxInsured {
        const result = new TaxInsured();
        result.insuredType = objectPath.get( input, "type");
        result.insuredName = objectPath.get( input, "party.name");
        result.insuredAddress = TaxAddress.buildFromCDRResponse( objectPath.get( input, "party.partyAddresses.0" ));
        return result;
    }
}
export class TaxIntermediary {
    intermediaryName = ""
    intermediaryType = "";
    intermediaryAddress? : TaxAddress;
    surplusLinesBrokerLicenseNumber?: string;

    static makeExample( t: string ): TaxIntermediary {
        const result = new TaxIntermediary();
        result.intermediaryType = t;
        if( t === "LLOYDS_BROKER") {
            result.intermediaryName = "Gail Broker";
            result.intermediaryAddress = TaxAddress.makeExample();
        }
        if( t === "RETAIL_BROKER") {
            result.intermediaryName = "Wilson, Wilson and Woodrup Inc";
            result.intermediaryAddress = TaxAddress.makeExample();
        }
        if( t === "SURPLUS_LINES_BROKER") {
            result.intermediaryName = "Trubshaws Inc";
            result.intermediaryAddress = TaxAddress.makeExample();
            result.surplusLinesBrokerLicenseNumber = "9876543";
        }
        return result;
    }
    static buildFromCDRResponse( input: any ): TaxIntermediary {
        const result = new TaxIntermediary();
        result.intermediaryType = objectPath.get( input, "type");
        result.intermediaryName = objectPath.get( input, "party.name");
        if( result.intermediaryType === "SURPLUS_LINES_BROKER") {
            result.surplusLinesBrokerLicenseNumber = objectPath.get( input, "surplusLinesBrokerDetails.surplusLinesBrokerNumber");
        }
        result.intermediaryAddress = TaxAddress.buildFromCDRResponse( objectPath.get( input, "party.partyAddresses.0" ) );
        return result;
    }
}
export class TaxInsuredItem {
    insuranceItemType = "";
    insuredItemGroup = "";
    insuredItemSubGroup = "";
    sumInsured = "";
    insuredItemAddress? : TaxAddress;
    static makeExample(): TaxInsuredItem {
        const result = new TaxInsuredItem();
        result.insuranceItemType = "FIXED_PROPERTY";
        result.insuredItemGroup = "PROPERTY";
        result.insuredItemSubGroup = "PROPERTY";    
        result.sumInsured = "1000000.00";
        result.insuredItemAddress = TaxAddress.makeExample();
        return result;
    }
    static buildFromCDRResponse( input: any ): TaxInsuredItem {
        const result = new TaxInsuredItem();
        result.insuranceItemType = objectPath.get( input, "type" );
        result.insuredItemGroup = objectPath.get( input, "insuredItemGroup" );
        result.insuredItemSubGroup = objectPath.get( input, "insuredItemSubGroup" );
        result.sumInsured = objectPath.get( input, "sumInsured" );
        result.insuredItemAddress = TaxAddress.buildFromCDRResponse( objectPath.get( input, "insuredItemPhysicalAddress.address" ) )
        return result;
    }
}

export class TaxAddress {
    streetNoAndStreet = "";
    city = "";
    zipCode = "";
    countrySubdivisionCode = "";
    countrySubDivisioncode = "";
    country = "";
    static makeExample(): TaxAddress {
        const result = new TaxAddress();
        result.streetNoAndStreet = "855 Main Street";
        result.city = "Vancouver";
        result.zipCode = "12345";
        result.countrySubdivisionCode = "CA-BC";
        result.countrySubDivisioncode = "CA-BC";
        result.country = "CA";        
        return result;
    }
    static buildFromCDRResponse( input: any ): TaxAddress {
        const result = new TaxAddress();
        result.streetNoAndStreet = objectPath.get( input, "streetNoAndStreet");
        result.city = objectPath.get( input, "city");
        result.zipCode = objectPath.get( input, "zipCode");
        result.countrySubdivisionCode = objectPath.get( input, "location.countrySubDivisionCode");
        result.countrySubDivisioncode = objectPath.get( input, "location.countrySubDivisionCode");
        result.country = objectPath.get( input, "location.ISOCountryShortName");
        return result;
    }
}


export class TaxCover {
    coverage: string[] = [];
    peril: TaxPeril[] = [];

    static makeExample(): TaxCover {
        const result = new TaxCover();
        result.coverage.push( "DAMAGE");
        result.peril.push( new TaxPeril( "CYBER", "EXCLUDED" ));
        result.peril.push( new TaxPeril( "FIRE","INCLUDED"));
        result.peril.push( new TaxPeril( "FLOOD", "INCLUDED"));
        result.peril.push( new TaxPeril( "EARTHQUAKE","INCLUDED"));
        return result;
    }

    static buildFromCDRResponse( input: any ): TaxCover {
        const result = new TaxCover();
        result.coverage.push( objectPath.get( input, "type" ) );
        result.peril = [ ...objectPath.get( input, "coverPerils" ) ];
        return result;
    }
}

export class TaxPeril {
    type = "";
    inclusionExclusionIndicator = "";
    constructor( t, indicator ) {
        this.type = t;
        this.inclusionExclusionIndicator = indicator;
    }
}