export const daffodil_tax_json = `
{
    "calculatedTaxes": [
      {
        "taxRiskLocation": {
          "streetNoAndStreet": "1 Main Street",
          "riskLocation": "US-IA",
          "rationale": "Location of Insured",
          "countryMatch": false
        },
        "applicableTaxes": [
          {
            "taxType": "Surplus Lines Tax / Self Procurement Tax",
            "taxCode": "US_IA_SLT",
            "taxBasisMethod": "Apportioned Premium (Insured Location)",
            "taxRate": {
              "taxApplicationType": "VARIABLE",
              "taxRate": 0.01
            },
            "responsibleParty": {
              "payableBy": "Insured",
              "administeredBy": "Insured - Surplus Lines Broker / Insured",
              "taxAuthority": "State Revenue Authority"
            },
            "taxCodePrefix": "US"
          }
        ]
      },
      {
        "taxRiskLocation": {
          "streetNoAndStreet": "1 Front Street",
          "riskLocation": "CA-BC",
          "rationale": "Location of Physical Item",
          "countryMatch": false
        },
        "applicableTaxes": [
          {
            "taxType": "Provincial Premium Tax",
            "taxCode": "CA_BC_PPT",
            "taxBasisMethod": "Apportioned Premium (Physical Item Location)",
            "taxRate": {
              "taxApplicationType": "VARIABLE",
              "taxRate": 0.044
            },
            "calculatedTax": [
              {
                "taxBasisAmount": 999999999,
                "taxAmount": 43999999.956
              }
            ],
            "responsibleParty": {
              "payableBy": "Insurer",
              "administeredBy": "Insurer - Lloyd's",
              "taxAuthority": "Provincial Revenue Authority"
            },
            "taxCodePrefix": "CA"
          }
        ],
        "apportionments": [
          {
            "contractPremium": 999999999,
            "premiumApportionmentMethod": "SUMINSURED",
            "premiumApportionmentMethodAmount": 999999999,
            "apportionedPremium": 999999999
          }
        ]
      }
    ],
    "aggregatedTaxes": [
      {
        "taxCode": "CA_BC_PPT",
        "taxType": "Provincial Premium Tax",
        "riskLocation": "CA-BC",
        "taxAmount": 43999999.96,
        "responsibleParty": {
          "payableBy": "Insurer",
          "administeredBy": "Insurer - Lloyd's",
          "taxAuthority": "Provincial Revenue Authority"
        }
      },
      {
        "taxCode": "US_IA_SLT",
        "taxType": "Surplus Lines Tax / Self Procurement Tax",
        "riskLocation": "US-IA",
        "message": "Potential US Surplus Lines or Self-Procurement tax applies",
        "responsibleParty": {
          "payableBy": "Insured",
          "administeredBy": "Insured - Surplus Lines Broker / Insured",
          "taxAuthority": "State Revenue Authority"
        }
      }
    ]
  }
  
`;