export const getbyparty_response_json = `{
    "cdrId": "ab4a0f08-b6df-45f2-9c29-0139148ee1d0",
    "services": {
      "taxCalculator": {
        "status": "ERROR",
        "code": "0002",
        "errors": [
          {
            "field": "dateContractEnteredInto",
            "message": "dateContractEnteredInto cannot be null"
          }
        ]
      },
      "riskLocations": {
        "status": "PASS"
      },
      "licenceStatusCheck": {
        "status": "PASS"
      },
      "idv": {
        "status": "PENDING"
      },
      "sanctions": {
        "status": "ERROR",
        "code": "0002",
        "errors": [
          {
            "field": "entityIdentifier",
            "message": "Please pass a valid company identifier. We currently accept 9 digit duns numbers."
          },
          {
            "field": "sanctionsOnCompanyRequest",
            "message": "Please pass a valid entity type, we currently accept COMPANY."
          }
        ]
      },
      "clauseChecker": {
        "status": "FAIL",
        "message": "One or more automated clause checks were failed: 9_000505: Unless prohibited by reason of local or legal requirements, check that there is a suitable exclusion for nuclear, chemical, biological and radioactive (NCBR) material used as a weapon, e.g. NMA2962, NMA1191, LMA5288.,4_810001: Ensure that the Market Reform Contract includes the Regulatory Client Classification in the Fiscal and Regulatory section,1_270001: Are any payment terms identified?"
      },
      "reglicenceChecker": {
        "status": "FAIL",
        "message": "One or more automated regulatory checks were failed: 1_250027-CA: For Open Market, Direct Insurance contracts in Canada (and contracts where the global policyholder is a Canadian located insured), ensure the contract contains the LMA5028A service of suit clause (can be amended to show a name other than the Attorney in Fact, but only with the latter's consent).,9_000358-CA: For Open Market, Direct Insurance contracts in Canada where the insured type is either Personal or Commercial, ensure that the contract contains either clause LSW1565c (English version) or clause LSW1565c-16 (French version). This is the agreed Lloyd's version of the Insurance Bureau of Canada's Code entitled 'Consumer Rights and Responsibilities'.,9_000385-CA: For Open Market, Direct Insurance contracts in British Columbia, Canada, ensure the contract contains clause LSW1815 to comply with the statutory conditions according to the British Columbia Insurance Act."
      }
    },
    "riskCodes": [
      "P5"
    ],
    "uniqueReference": "B9988202106161236",
    "brokerPolicyReference": "8202106161236",
    "insuranceContractType": "INSURANCE",
    "insuranceContractSubType": "INSURANCE",
    "methodOfPlacement": "OPEN_MARKET",
    "startOfInsurancePeriod": "2098-01-01T00:01:00Z",
    "endOfInsurancePeriod": "2099-01-01T00:01:00Z",
    "basisOfAuthorisation": "US_SURPLUS_LINES",
    "contractOriginalCurrency": "USD",
    "originalLanguageOfWordings": "EN",
    "choiceOfLawAndJurisdiction": {
      "country": {}
    }}
  
`;