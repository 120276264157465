export const sortUpdatedAt = (a, b) => {
    if(a.updatedAtUnix > b.updatedAtUnix) {
        return 1
    }
    else if(a.updatedAtUnix < b.updatedAtUnix) {
        return -1
    }
    else {
        return 0
    }
}