import axios from 'axios'

export const pdfmaker = {
    download : function( title, input ) {
        console.log('pdfmaker', input)
        axios
        .post('https://dev.whitespace.co.uk/pdfmake/', input, { 
            responseType: 'arraybuffer',
            headers: { 
                'Accept': '*/*',
                'Content-type': 'application/json',
            }
        })
        .then(response => {
            const blob = new Blob([response.data], {type: response.headers['content-type']})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = title;
            link.click()
        })
        .catch(function (error) {
            console.error(error)
            return (error.message === 'Network Error') ? 'Network Error' : false
        })
    }
}