import { PageLoading, NoResultsFound } from '../../components/Loading'
import React, { useState, useEffect } from 'react'
import { whitespace, Query } from '../../helpers/whitespace'
import { riskFilter } from '../../helpers/riskFilter'
import { RiskGrid } from '../../components/RiskGrid'
import { MRCViewer } from '../../rebrowse/MRCViewer'
import './Dashboard.css'

export const Dashboard = ({ refresh }) => {
    const [loaded, setLoaded] = useState(false)
    const [risks, setRisks] = useState<any[]>([])
    const [viewingMRC, setViewingMRC] = useState(false)
    const [selectedRisk, setSelectedRisk] = useState<any>( null )

    useEffect(() => {
        whitespace(Query.GET, '/api/summary').then(response => {
            setRisks( riskFilter.groupRisksSummary( response.data ))
            setLoaded(true)
        })
    }, [refresh])

    const pickRisk = ( risk ) => {
        console.log( 'pickRisk', risk )
        whitespace(Query.GET, `/api/risks/${risk.id}/getExtendedMRC`).then( response => {
            setSelectedRisk( response.data )
            setViewingMRC( true )
        })
    }

    if( viewingMRC ) {
        const divStyle = { fontSize: 'small', paddingRight:'6px' }
        return (
            <>
            <div className='FloatRight' style={divStyle} >
                <span className='MyIcon' onClick={() => {
                setViewingMRC(false)
            }}>☒</span>
            </div>
            <MRCViewer lineItems={selectedRisk.MRCContract.lineItems}/>
            </>
        )
    } else {
        return (
            <>
            {!loaded && <PageLoading />}
            {loaded && !risks.length && <NoResultsFound />}
            <div className={`Fadein-wrapper ${risks.length && 'load'}`}>
                <RiskGrid input={risks} pickFn={pickRisk} />
            </div>
            </>
        )
    }
}