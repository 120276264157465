import React, { useState, useEffect } from 'react'
import { Query, whitespace } from '../../helpers/whitespace'
import './CDRCaller.css'
import { ExamplePayload } from './ExamplePayload'
import { daffodil_cdr_json } from './daffodil.cdr' 
import { daffodil_tax_json } from './daffodil.tax';
import { getbyparty_response_json } from './GetByPartyResponse';
import { McGillDetails } from './McGillDetails'
import axios from 'axios'
import { RiskGrid } from '../../components/RiskGrid'
import { PageLoading } from '../../components/Loading'
import { riskFilter } from '../../helpers/riskFilter'
import { TaxPayload } from './TaxPayload';
import { Row, Col } from 'react-bootstrap'
import { CDRXMRCViewer, CDRExtractViewer, CDRResponseViewer, CDRTaxViewer } from './CDRViewers';
import { NotificationManager } from 'react-notifications'

const targets : any[] = [
    {
        name: "SIT",
        gateway : "https://api-digitalgateway.sit.spine530.com/v1.0",
        tax: "https://api-taxcalculator.sit.spine530.com/v1.0",
        apikey: "c7abb03f-82c7-4e29-a2f7-4fc2bb46ec55"
    },
    {
        name: "STG",
        gateway : "https://api-digitalgateway.stg.spine530.com/v1.0",
        tax: "https://api-taxcalculator.stg.spine530.com/v1.0",
        apikey: "ec432dbd-c6d7-4b2f-b825-0d6eafe9314a"
    },
    {
        name: "MAT",
        gateway : "https://api-digitalgateway.mat.spine530.com/v1.0",
        tax: "https://api-taxcalculator.mat.spine530.com/v1.0",
        apikey: "4cf27afc-ef1b-410e-8969-cd97ec6621e3"

    }
];

export const CDRCaller = () => {
    const [jsonResult, setJSONResult] = useState( '' )
    const [extractResponse, setExtractResponse] = useState<any>(null)
    const [fetchingData, setFetchingData] = useState(true)
    const [risks, setRisks] = useState<any[]>([])
    const [ruleset, setRuleset] = useState( 'altecStyle' )
    const [riskID, setRiskID] = useState( '' )
    const [xmrc, setXMRC] = useState<any>( null )
    const [showRiskGrid, setShowRiskGrid] = useState( true )
    const [showDebugging, setShowDebugging] = useState( false )
    const [cdrResponse, setCDRResponse] = useState<any>( null );
    const [taxResponse, setTaxResponse] = useState<any>( null );
    const [partyID, setPartyID] = useState( '' )
    const [cdrID, setCDRID] = useState('')
    const [asColumns, setAsColumns] = useState(true)
    const [target, setTarget] = useState<any>( targets[2] );    // default to MAT
    useEffect(() => {
        setFetchingData(true)
        whitespace(Query.GET, '/api/risks').then(response => {
            setFetchingData(false)
            setRisks( riskFilter.groupAllRisks( response?.data || [] ) )
        })
    }, [])

    const pickRuleset = ( evt ) => {
        setRuleset( evt.target.value )
    }

    const pickRisk = ( risk ) => {
        setRiskID( risk.id );
        clearFromStage( 1 );
        whitespace(Query.GET, `/api/risks/${risk.id}/getExtendedMRC`).then( response => {
            console.log( "getExtendedMRC", response.data );
            setXMRC( response.data );
            setJSONResult( JSON.stringify( response.data, null, 2 ) );
            setShowRiskGrid( false );
        })
    }

    const toggleRiskGrid = () => {
        setShowRiskGrid( !showRiskGrid )
    }

    const clearXMRC = () => {
        setXMRC(null)
    }
    const XMRCPanel = () => {
        return (
            <>
            <div className="DlgSubTitle">Insured / Status / DocID</div>{xmrc?.control?.insuredName} / {xmrc?.control?.status} / {xmrc?.platformReferences?.RiskID}
            </>
        )
    }

    const clickExample = () => {
        setJSONResult( JSON.stringify( ExamplePayload, null, 2 ) )
        setExtractResponse( ExamplePayload )
    }
    const clickMcGillDetails = () => {
        setJSONResult( JSON.stringify( McGillDetails, null, 2 ) )
    }


    const callWsVersion00 = () => {
        callWsAPI( '/version')
    }

    const callWsVersion = () => {
        callWsAPI( '/cdr/version')
    }
    const callWsRules = () => {
        callWsAPI( '/cdrrules')
    }
    const clearFromStage = ( stage: number ) => {
        if( stage < 2 ) {
            setExtractResponse( null );
        }
        if( stage < 3 ) {
            setCDRResponse( null );
        }
        if( stage < 4 ) {
            setTaxResponse( null );
        }
    }
    const callWsMultipart = () => {
        clearFromStage( 1 );
        if( !xmrc )
            return
        const formData = new FormData();

        formData.append( "BrokerCorporate", JSON.stringify(McGillDetails));
        formData.append( "ExtendedMRC", JSON.stringify(xmrc) );
        setExtractResponse( {} )
        const url = 'https://dev.whitespace.co.uk/extract/cdrMultipart?ruleset=' + ruleset
        setJSONResult( `Calling ${url}`)
        axios.post(url, formData, { 
            //headers: formData.getHeaders() // 
            headers: { 'Content-type': 'multipart/form-data', }
        })
        .then(response => {
            displayCallResult( url, response )
            if( response?.data ) {
                setExtractResponse( response.data );
                setShowRiskGrid( false );
                if( response.data.intermediaries[0]?.party?.partyIdentifiers[0]?.id ) {
                    setPartyID( response.data.intermediaries[0]?.party?.partyIdentifiers[0]?.id )
                }

            }

        })
        .catch(function (error) {
            console.error(error)
            setJSONResult( JSON.stringify( error, null, 2 ) )
            return
        })    
    }

    const callWsExtract = () => {
        if( !xmrc )
            return
        setExtractResponse( {} )
        const url = 'https://dev.whitespace.co.uk/extract/cdr?ruleset=' + ruleset
        setJSONResult( `Calling ${url}`)
        axios.post(url, xmrc, { 
            headers: { 
                'Content-type': 'application/json',
            }
        })
        .then(response => {
            displayCallResult( url, response )
            if( response?.data ) {
                setExtractResponse( response.data )
            }

        })
        .catch(function (error) {
            console.error(error)
            setJSONResult( JSON.stringify( error, null, 2 ) )
            return
        })
    }

    const callWsAPI = ( endpoint ) => {
        const url = 'https://dev.whitespace.co.uk/extract' + endpoint
        setJSONResult( `Calling ${url}`)
        axios.get(url, { 
            headers: { 
                'Content-type': 'application/json',
            }
        })
        .then(response => {
            displayCallResult( url, response )
         })
        .catch(function (error) {
            console.error(error)
            setJSONResult( JSON.stringify( error, null, 2 ) )
            return
        })
    }

    const displayCallResult = ( url, response ) => {
        let result = {}
        if( response?.data ) {
            result = response.data
        } 
        else if ( response ) {
            result = response
        }
        else {
            result = { msg: 'Error', url: url }
        }
        setJSONResult( JSON.stringify( result, null, 2 ) )
    }

    // const CDRROOT = "https://api-digitalgateway.sit.spine530.com/v1.0";
    // const APIKEY = "c7abb03f-82c7-4e29-a2f7-4fc2bb46ec55";

    const clickCallByParty = () => {
        if( !cdrID || !partyID ) {
            NotificationManager.error( "cdrID and partyID required" );
            setJSONResult( JSON.stringify( { "error" : "cdrID and partyID required", "cdrID" : cdrID, "partyID" : partyID }));
            return;
        }
        const url = `${target.gateway}/contracts/${cdrID}/party/${partyID}`;
        doCDRCall( url, null, parseByPartyCall );
    }

    const mimicCallByParty = () => {
        console.log( "mimicCallByParty")
        console.log( getbyparty_response_json );
        const result = JSON.parse( getbyparty_response_json );
        console.log( result )
        parseByPartyCall( result );
    }

    const parseByPartyCall = ( result : any ) => {
        setJSONResult( JSON.stringify( result, null, 2 ) );
        setCDRID( result.cdrId || '' )            
        setCDRResponse( result );
    }

    const clickCallCDR = () => {
        const url = `${target.gateway}/contracts`
        clearFromStage( 2 );
        doCDRCall( url, tidyExtractResponse(extractResponse), parseCDRInitialCall );
    }

    const tidyExtractResponse = ( input ) => {
        const result = input;
        const len = result.intermediaries?.length || 0;
        for( let i = len - 1; i >= 0; i-- ) {
            const item = result.intermediaries[i];
            if( !item.party || !item.party?.name ) {
                console.log( `tidyExtractResponse removing intermediary ${i}` );
                result.intermediaries.splice( i, 1 );
            }
        }
        return result;
    }

    const doCDRCall = ( url, postPayload, handlerfn ) => {
        setJSONResult( `Calling ${url}`)
        console.log( postPayload ? `POST ${url}` : `GET ${url}` );

        axios( {
            method: postPayload ? "post" : "get",
            url: url,
            data: postPayload,
            headers: { 
                'Content-Type': 'application/json',
                'apiKey' : target.apikey
            }
        } ).then(response => {
            let result : any = {}
            if( response?.data ) {
                result = response.data
            } 
            else if ( response ) {
                result = response
            }
            else {
                result = { msg: 'Error', url: url }
            }
            handlerfn( result )
         })
        .catch(function (error) {
            console.error(error)
            setJSONResult( JSON.stringify( error, null, 2 ) )
            return
        })
    }

    const mimicCallCDR = () => {
        const input = JSON.parse( daffodil_cdr_json );
        parseCDRInitialCall( input )
    }

    const parseCDRInitialCall = ( result : any ) => {
        clearFromStage( 3 );
        setJSONResult( JSON.stringify( result, null, 2 ) )
        setCDRID( result.cdrId || '' )            
        setCDRResponse( result );
    }

    const mimicCallTax = () => {
        const input = JSON.parse( daffodil_tax_json );
        clearFromStage( 4 );
        parseTaxCall( input );
    }

    const clickCallTax = () => {
        const url = `${target.tax}/tax-calculator/taxes`;
        const taxPayload = clickPrepareTaxPayload();
        doCDRCall( url, taxPayload, parseTaxCall );
    }

    const parseTaxCall = ( result : any ) => {
        setJSONResult( JSON.stringify( result, null, 2 ) )
        setTaxResponse( result );
    }

    const clickModelTaxPayload = () => {
        const taxPayload = TaxPayload.makeExample();
        setJSONResult( JSON.stringify( taxPayload, null, 2 ) );
        // abcdefghijklmnopqr
    }
    const clickPrepareTaxPayload = () : any => {
        const input = cdrResponse || JSON.parse( daffodil_cdr_json );
        console.log( input );
        const result = TaxPayload.buildFromCDRResponse( input );
        setJSONResult( JSON.stringify( result, null, 2 ) );
        return result;
    }

    const clickNYI = () => {
        NotificationManager.warning( "Not Yet Implemented");
    }

    const toggleTarget = () => {
        if( target.name === "SIT" ) {
            pickTarget( "STG");
        }
        if( target.name === "STG" ) {
            pickTarget( "MAT");
        }
        else {
            pickTarget( "SIT");
        }
    }

    const pickTarget = ( name ) => {
        console.log( `pickTarget ${name}`);
        const picked = targets.filter( itm => itm.name === name)[0];

        setTarget( picked || targets[0] );
        // setTarget( targets.filter( (itm) => { itm.name == name } )[0] || targets[0] );
    }

    const clickUpdateRisk = () => {
        if( !riskID ) {
            NotificationManager.warning( "Risk not selected");
            return;
        }
        if( !taxResponse ) {
            NotificationManager.warning( "Tax Response not received");
            return;
        }
        whitespace(Query.GET, `/api/risks/${riskID}`).then( response => {
            const rev = response.data._rev;
            const originalHeading = "CDR TAX DATA";
            const mrcSection = "SubscriptionAgreement";
            const lineitem = response.data.MRCContract.lineItems.filter( itm => itm.originalHeading === originalHeading )[0];
            const elements: any[] = buildTaxLineItem();
            const libi_payload = { items: [ { elements : elements, index: -1} ], _rev: rev };
            if( lineitem ) {
                libi_payload.items[0].index = lineitem.index;
                console.log( libi_payload );
                whitespace(Query.POST, `/api/risks/${riskID}/elementsLineItemByIndex`, libi_payload ).then( response => {
                    NotificationManager.warning( `Updated ${originalHeading}`);
                    setJSONResult( JSON.stringify( response.data, null, 2 ) );
                })
            } else {
                const inSectionArray = response.data.MRCContract.lineItems.filter( itm => itm.mrcSection === mrcSection );
                console.log( inSectionArray);
                if( !inSectionArray || !inSectionArray.length ) {
                    NotificationManager.warning( `No section ${mrcSection}` );
                    return;
                }
                const maxIndex = Math.max( ... inSectionArray.map( s => s.index ) );
                // NotificationManager.warning( `Inserting ${originalHeading} at end of ${inSectionArray.length} items after ${maxIndex}`);
                const ch_payload = { insert: [ { index: maxIndex, new_heading: originalHeading}], delete: [], modify : [], _rev : rev };
                whitespace(Query.POST, `/api/risks/${riskID}/changeHeadings`, ch_payload ).then( response => {
                    libi_payload.items[0].index = maxIndex + 1;
                    libi_payload._rev = response.data._rev;
                    console.log( libi_payload );
                    whitespace(Query.POST, `/api/risks/${riskID}/elementsLineItemByIndex`, libi_payload ).then( response => {
                        NotificationManager.warning( `Inserted ${originalHeading}`);
                        setJSONResult( JSON.stringify( response.data, null, 2 ) );
                    });
                })
            }
        })
    }

    const buildTaxLineItem = () : any[] => {
        const elements: any[] = [];
        let i = 0;
        if( taxResponse.calculatedTaxes ) {
            elements.push( { text: "Calculated Taxes", html: "<b><u>Calculated Taxes</u></b>", index: i }); i++;
            taxResponse.calculatedTaxes.map( (itm, idx) => {
                itm.applicableTaxes.map((jtm,jdx) => {
                    elements.push( { text : jtm.taxType, html: `<u>${jtm.taxType}</u>`, index: i }); i++; 
                    elements.push( { text : `Basis Method: ${jtm.taxBasisMethod}`, index: i }); i++; 
                    elements.push( { text : `Rate: ${jtm.taxRate.taxRate} ${jtm.taxRate.taxApplicationType}`, index: i }); i++; 
                    elements.push( { text : `Tax Code: ${jtm.taxCode}`, index: i }); i++; 

                });
            });
        }
        if( taxResponse.aggregatedTaxes ) {
            elements.push( { text: "Aggregated Taxes", html: "<b><u>Aggregated Taxes</u></b>", index: i }); i++;
            taxResponse.aggregatedTaxes.map( (itm, idx) => {
                elements.push( { text : itm.taxType, html: `<u>${itm.taxType}</u>`, index: i }); i++; 
                elements.push( { text : `Tax Code: ${itm.taxCode}`, index: i }); i++; 
                elements.push( { text : `Amount: ${itm.taxAmount || ''}`, index: i }); i++; 
            });
        }
        return elements;
    }

    const serviceDetailDisplay = ( ob: any ) => {
        setJSONResult( JSON.stringify( ob, null, 2 ) );
    }


    return (
        <>
            <div>
            <div className="FloatLeft">
            {!showDebugging && <span className='IconLink' onClick={() => setShowDebugging(true)}>↓</span>}
            {showDebugging && <span className='IconLink' onClick={() => setShowDebugging(false)}>↑</span>}
            &nbsp;<span className='MyLink' onClick={toggleRiskGrid}>Toggle Risk Picker</span>            
            &nbsp;Connected to <span className='MyLink' onClick={toggleTarget}>{target.name}</span>
            </div>
            <div className="FloatRight LightGray XSmallText">
                26 July 2021 16:52 utility
            </div>
            <div className="ClearBoth"></div>
            {false && extractResponse && <>&nbsp;|&nbsp;<span className='MyLink' onClick={clickCallCDR}>Call CDR</span></>}
            {false && cdrID && partyID && <>&nbsp;|&nbsp;<span className='MyLink' onClick={clickCallByParty}>By Party</span></>}
            {showDebugging && 
                <>
                {xmrc && <><span>&nbsp;|&nbsp;</span>
                    <select onChange={pickRuleset}>
                        <option>altecStyle</option>
                        <option>cdrexample</option>
                        <option>icd14</option>
                    </select> 
                    &nbsp;|&nbsp;<span className='MyLink' onClick={callWsMultipart}>MultiPart</span>
                    <XMRCPanel/>
                    </> 
                }
                <div>
                    Target {target.name} {target.gateway}
                    &nbsp;|&nbsp;<span className='MyLink' onClick={ () => pickTarget('SIT')}>SIT</span>
                    &nbsp;|&nbsp;<span className='MyLink' onClick={ () => pickTarget('STG')}>STG</span>
                    &nbsp;|&nbsp;<span className='MyLink' onClick={ () => pickTarget('MAT')}>MAT</span>
                </div>
                <div>
                <span className='MyLink' onClick={clickExample}>Example</span>
                &nbsp;|&nbsp;<span className='MyLink' onClick={clickMcGillDetails}>McGill Details</span>
                &nbsp;|&nbsp;<span className='MyLink' onClick={callWsVersion00}>Ver</span><span className='MyLink' onClick={callWsVersion}>sion</span>
                &nbsp;|&nbsp;<span className='MyLink' onClick={clearXMRC}>Clear XMRC</span>
                &nbsp;|&nbsp;<span className='MyLink' onClick={callWsRules}>Rules</span>
                &nbsp;|&nbsp;<span className='MyLink' onClick={callWsExtract}>Send</span>
                &nbsp;|&nbsp;<span className='MyLink' onClick={mimicCallCDR}>Mimic CDR</span>
                &nbsp;|&nbsp;<span className="MyLink" onClick={mimicCallByParty}>Mimic GBP</span>
                &nbsp;|&nbsp;<span className='MyLink' onClick={clickModelTaxPayload}>TaxPayload</span>
                &nbsp;|&nbsp;<span className='MyLink' onClick={clickPrepareTaxPayload}>Prepare</span>
                &nbsp;|&nbsp;<span className="MyLink" onClick={mimicCallTax}>Mimic Call Tax</span>
                <br/>
                {partyID && <> Party ID:{partyID}</> }
                {cdrID && <> CDR ID:{cdrID}</> }
                </div>  
                </>  
            }
            </div>
        
        {fetchingData && <PageLoading />}
        {!fetchingData && showRiskGrid && <><div className='Above12'><RiskGrid input={risks} pickFn={pickRisk} /></div></> }
        {
            asColumns && <>
            <Row>
                <Col className='ColHeader Col1Header'>Whitespace JMRC<br/>
                    <span className="MyLink" onClick={callWsMultipart}>Next</span>
                </Col>
                <Col className='ColHeader Col2Header'>Whitespace Extract<br/>
                    <span className="MyLink" onClick={clickCallCDR}>Next</span>
                </Col>
                <Col className='ColHeader Col3Header'>Lloyd's Checks<br/>
                    <span className="MyLink" onClick={clickCallByParty}>Refresh</span>
                    &nbsp;|&nbsp;<span className="MyLink" onClick={clickCallTax}>Next</span>
                </Col>
                <Col className='ColHeader Col4Header'>Lloyd's Tax<br/>
                <span className="MyLink" onClick={clickUpdateRisk}>Next</span>
                </Col>
            </Row>
            <Row>
                <Col className='ColData Col1Data'><CDRXMRCViewer xmrc={xmrc}/></Col>
                <Col className='ColData Col2Data'><CDRExtractViewer extract={extractResponse}/></Col>
                <Col className='ColData Col3Data'><CDRResponseViewer response={cdrResponse} clickHandler={serviceDetailDisplay} /></Col>
                <Col className='ColData Col4Data'><CDRTaxViewer response={taxResponse} /></Col>
            </Row>
            </>
        }
        <div className='Above12 JSONResult'>
        <pre>
            {jsonResult}
        </pre>
        </div>
        </>
    )
} 
