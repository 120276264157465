import {JMRC} from './jmrc'

export class Rect {
    x0: number;
    y0: number;
    x1: number;
    y1: number;

    constructor(x0 = Number.MAX_SAFE_INTEGER, y0 = Number.MAX_SAFE_INTEGER, x1 = Number.MIN_SAFE_INTEGER, y1 = Number.MIN_SAFE_INTEGER) {
        this.x0 = x0
        this.y0 = y0
        this.x1 = x1
        this.y1 = y1
    }
}

export function LookupPlaceHolder(key: string, placeholders: JMRC.Placeholder[]): string | null {
    const ph = (placeholders || []).find((p) => p.name === key)

    if(!ph) {
        return null
    }

    if(ph.phType === 'TimeDuration') {
        return ph.value.split(' ').map((part) => (parseFloat(part) && parseFloat(part).toLocaleString('en-GB')) || part).join(' ')
    }

    return ph.value
}

export function ExpandPlaceHolders(str: string | undefined, placeholders: JMRC.Placeholder[]): string {
    if(!str) {
        return ''
    }
    return str.replace(/\[\[([^[\]]+)\]\]/g, (match, p1) => {
        return LookupPlaceHolder(p1, placeholders) || match
    })
}

export enum LineItemElementType {
    Paragraph,
    Tree,
    Table,
}

export enum ListStyle {
    none = 'none',
    unordered = 'unordered',
    ordered = 'ordered'
}

export interface LineItemTextSpan {
    text: string;
    html?: string;
    isPlaceholder: boolean;
    placeholderID?: string;
}

export class LineItemTableRow {
    constructor(public cells: string[], public height: number) {}
}

export class LineItemTable {
    public jmrcTable: JMRC.Table;
    public bounds: Rect;
    public rows: LineItemTableRow[];
    public pageSpan: [number, number];

    public rowSpecs: JMRC.TableRowSpec[];
    public colSpecs: JMRC.TableColSpec[];

    constructor(table: JMRC.Table, placeholders: JMRC.Placeholder[]) {
        this.jmrcTable = table

        this.bounds = new Rect()
        this.pageSpan = [ Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER ]

        //console.log("processing table: %o", table);

        if(table.columnsSpecs && table.columnsSpecs.length > 0) {
            for(const col of table.columnsSpecs) {
                if(col.x < this.bounds.x0) {
                    this.bounds.x0 = col.x
                }

                if(col.x > this.bounds.x1) {
                    this.bounds.x1 = col.x
                }
            }
            this.colSpecs = table.columnsSpecs
        } else {
            this.colSpecs = []
        }

        if(table.rowsSpecs && table.rowsSpecs.length > 0) {
            for(const row of table.rowsSpecs) {
                if(row.page < this.pageSpan[0] || (row.page === this.pageSpan[0] && row.y < this.bounds.y0)) {
                    this.pageSpan[0] = row.page
                    this.bounds.y0 = row.y
                }

                if(row.page > this.pageSpan[1] || (row.page === this.pageSpan[1] && row.y > this.bounds.y1)) {
                    this.pageSpan[1] = row.page
                    this.bounds.y1 = row.y
                }
            }
            this.rowSpecs = table.rowsSpecs.sort((a, b) => a.page !== b.page ? a.page - b.page : a.y - b.y)
        } else {
            this.rowSpecs = []
        }

        if(table.rows) {
            this.rows = table.rows.map(r => {
                const values = r.values.map(v => ExpandPlaceHolders(v, placeholders))
                return new LineItemTableRow(values, r.height)
            })
        } else {
            this.rows = []
        }
    }
}

export interface LineItemElement {
    type:      LineItemElementType;

    // type === Tree
    children?: LineItemElement[];

    // type === Table
    table?:    LineItemTable; // type == Table

    // type === Paragraph
    text?:     string;
    safeHTML?: string;
    spans?:    LineItemTextSpan[];

    bullet?:   string;
    style?:    ListStyle;
}

export class LineItem {
    public name: string;

    // TODO: rename to avoid confusion with JMRC elements
    public elements: LineItemElement[];
    public text: string;
    public containsTables: boolean;

    // TODO check if needed
    public uniqueName?: string;
    get lineUniqueName(): string {
        return this.uniqueName || this.name
    }

    constructor(line: JMRC.LineItem) {
        this.elements = []
        this.name = this.text = ''
        this.containsTables = false

        if(!line) {
            return
        }

        this.name = line.originalHeading
        this.uniqueName = line.uniqueHeading

        if(!line.elements) {
            console.warn("LineItem doesn't have elements: %o", line)
            return
        }

        this.setElements(line.elements, line.placeholders)
    }

    public static flattenElements(input_elements: JMRC.Element[]): JMRC.Element[] {
        const elements = input_elements.slice().map(e => Object.assign({}, e))

        for(let i = 0; i < elements.length; ++i) {
            const e = elements[i]

            if(!e.textArray) {
                continue
            }

            const hasBullet = !!e.bulletValue

            const new_elems: any = []
            for(const t of e.textArray) {
                const new_e = Object.assign({}, e)
                delete new_e['textArray']

                if(hasBullet && new_elems.length > 0) {
                    delete new_e['bulletValue']
                    new_e.indent = (new_e.indent||0) + 1
                }

                new_e.text = t
                new_elems.push(new_e)
            }

            elements.splice(i, 1, ...new_elems)
        }

        return elements
    }

    public setElements(elements: JMRC.Element[], placeholders: JMRC.Placeholder[]) {
        this.elements = []

        // i don't like this double nesting in elements, flatten it.
        const jmrc_elements = LineItem.flattenElements(elements)

        // Generate our renderable LineItemElements

        // This code is pretty confusing so a brief explanation:
        //  - It is building a tree of the LineItemElement class into this.elements
        //    (initial value of 'node' above)
        //  - Note that this.elements is not the same as jmrc_elements which are from the json...
        //  - The tree branches whenever a new level of indentation is required
        //    - It is represented by adding  LineItemElement with type .Tree with a children list,
        //      also of type LineItemElement.
        //    - When this happens, item_ptr is pointing to this new children array
        //  - The tree structure is used in line-item-view.component.html
        //    - This angular component embeds a copy of itself inside a <ol> </ol> pair to achieve
        //      the next level of indentation whenever it encounters LineItemElementType.Tree
        //  - item_ptr is used to refer to the current level of the tree.
        //    - It points at the indentation_stack array above initially, which forms the
        //      root level of the tree.
        //    - This corresponds to the unindented (top-level) paragraphs etc in the html output.

        // written to by item_ptr below
        const indentation_stack = [{ node: this.elements, level: 0 }]

        this.containsTables = false
        this.text = ''

        for(const elem of jmrc_elements) {
            let item_ptr = indentation_stack[0].node

            const indent_data = this.getIndentDataForElements(elem)
            let level_diff = indent_data.level - indentation_stack[0].level

            while(level_diff < 0 && indentation_stack.length > 0) {
                indentation_stack.shift()
                item_ptr = indentation_stack[0].node
                level_diff++
            }

            while(level_diff > 0) {
                const parent = item_ptr
                item_ptr = []
                level_diff--

                indentation_stack.unshift({ node: item_ptr, level: indent_data.level - level_diff })

                parent.push({
                    type: LineItemElementType.Tree,
                    children: item_ptr,
                    style: indent_data.style,
                })
            }

            if(elem.table !== undefined) {
                item_ptr.push({
                    type: LineItemElementType.Table,
                    table: new LineItemTable(elem.table, placeholders)
                })

                for(const r of elem.table.rows) {
                    for(const v of r.values) {
                        this.text += v + '\t'
                    }
                    this.text += '\n'
                }

                this.containsTables = true
            } else if(elem.text !== undefined) {
                let p = elem.text.trim()

                // no text but a bullet - questionable...
                if(p === '' && elem.bulletValue) {
                    p = elem.bulletValue
                }

                const text = ExpandPlaceHolders(p, placeholders)

                for(let level = 0; level < indent_data.level; ++level) {
                    this.text += '\t'
                }

                if(indent_data.bullet) {
                    this.text += indent_data.bullet + ' '
                }

                this.text += `${text}\n`

                item_ptr.push({
                    type: LineItemElementType.Paragraph,
                    text: text,
                    safeHTML: SanitizeHTML(ExpandPlaceHolders(elem.html, placeholders)),
                    bullet: indent_data.bullet,
                    spans: this.createTextSpans(elem, placeholders)
                })
            } else {
                console.error('no text or table: %o', elem)
            }
        }

        this.text = ExpandPlaceHolders(this.text, placeholders)
    }

    private createTextSpans(elem: JMRC.Element, placeholders: JMRC.Placeholder[]): LineItemTextSpan[] {

        const text = elem.text || ''
        const spans: LineItemTextSpan[] = []
        const regex = new RegExp(/\[\[([^[\]]+)\]\]/, 'g')

        let index = 0
        let match: RegExpMatchArray | null = null

        while ((match = regex.exec(text)) !== null) {

            const placeholderID = match[1]
            const placeholderValue = placeholders.find(ph => ph.name === placeholderID)

            if(placeholderValue !== undefined) {
                if(match.index && match.index > index) {
                    spans.push({
                        text: text.substring(index, match.index),
                        isPlaceholder: false,
                    })
                }

                index = regex.lastIndex

                spans.push({
                    text: placeholderValue.value,
                    isPlaceholder: true,
                    placeholderID: placeholderID,
                })
            } else {
                spans.push({
                    text: text.substring(index, regex.lastIndex),
                    isPlaceholder: false,
                })
                index = regex.lastIndex
            }
        }

        if(index < text.length) {
            spans.push({
                text: text.substring(index),
                isPlaceholder: false,
            })
        }

        return spans
    }

    private getIndentDataForElements(elem: JMRC.Element) {
        let bullet = elem.bulletValue
            ? elem.bulletValue.trim()
            : ''

        let indent = elem.indent || 0
        if(indent < 0) {
            indent = 0
        }

        // dodgy bullet with no text
        if(elem.text !== undefined && elem.text.trim() === '') {
            bullet = ''
        }

        if(bullet !== '') {
            indent++
        }

        let style = ListStyle.none
        if(bullet === '\u{2022}' || bullet === '-') {
            style = ListStyle.unordered
        } else if(bullet !== '') {
            style = ListStyle.ordered
        }

        return {
            bullet: bullet,
            level: indent,
            style: style,
        }
    }

    public traverseElements(callback: (lie: LineItemElement) => void) {
        const stack: LineItemElement[] = this.elements.slice().reverse()

        while(stack.length > 0) {
            const i: LineItemElement = stack.pop()!
            callback(i)

            if(i.children) {
                for(const c of i.children.slice().reverse()) {
                    stack.push(c)
                }
            }
        }
    }

    public getTableByIndex(index: number): LineItemTable | null {
        let table: LineItemTable | null | undefined = null

        if(!this.containsTables) {
            return null
        }

        this.traverseElements(e => {
            if(e.type === LineItemElementType.Table && index-- === 0) {
                table = e.table
            }
        })

        return table
    }
}

export function SanitizeHTML(html: string): string | undefined {
    if(html === undefined) {
        return undefined
    }

    let result = ''

    for(let i = 0; i < html.length; ++i) {
        const c = html[i]

        let tag: string | undefined = undefined

        if(c === '<') {
            const end = html.indexOf('>', i)
            if(end !== -1) {
                tag = html.substring(i+1, end)
                i = end
            }
        }

        if(tag) {
            const normtag = tag[0] === '/' ? tag.substring(1) : tag
            if(['b', 'u', 'i'].includes(normtag)) {
                result += `<${tag}>`
            }
        } else {
            // not escaping c here, since .html should be escaped already.
            // should be safe since unknown tags removed above.
            result += c
        }
    }

    return result
}

export enum OrganisedCategory { Line, Section }

export function OrganiseLineItems(_items: JMRC.LineItem[], callback: (type: OrganisedCategory, line: JMRC.LineItem, parent?: JMRC.LineItem) => void) {
    const sections: any = []
    const out_of_order: any = []
    const items = _items.sort((a, b) => a.index - b.index)

    for(const line of items) {

        // ReVAPI peculiarity
        if(line.potentialSection === 'Unknown') {
            delete line.potentialSection
        }

        const sect = sections.find(s => s.mrcSection === line.mrcSection)

        if (!sect) {
            if (line.originalSection === 'self') {
                sections.push(line)
                callback(OrganisedCategory.Section, line)
            } else {
                out_of_order.push(line)
            }
        } else {
            if(line.originalSection !== 'self') {
                callback(OrganisedCategory.Line, line, sect)
            }
        }
    }

    for(const line of out_of_order) {
        callback(OrganisedCategory.Line, line, sections.find(s => s.mrcSection === line.mrcSection))
    }
}