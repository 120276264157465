import React, { useState, useEffect } from 'react'
import { whitespace, Query } from '../../helpers/whitespace'
import DataTable from 'react-data-table-component'
import { Modal, Col, Row, Button } from 'react-bootstrap'
import { Risk } from '../../components/Risk'
import { PageLoading } from '../../components/Loading'
import './Reinsurance.css'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { sortUpdatedAt } from '../../helpers/processes'
import { NotificationManager } from 'react-notifications'
import axios from 'axios'
import session from './session.json'

dayjs.extend(relativeTime)
const wsDatetimeFormat = 'YYYY-MM-DD HH:mm:ss'

const externalBrokers = [
    {
        id: 'pf',
        name: 'Price Forbes',
        teamChannel: 'priceforbes_WSDEV', // Perhaps a teamChannel for the incoming McGriff broker service user could be used here?
        sessionToken: session.id_token
    }
]

const externalBrokerAuth = {
    headers: {
        'Authorization': `Bearer ${externalBrokers[0].sessionToken}`,
    } 
}

const columns = [
    {
        name: 'Risk Name',
        selector: 'InsuredName',
        sortable: true,
    },
    {
        name: 'Limit',
        selector: 'limitSummary',
        sortable: true,
        right: true,
    },
    {
        name: 'Status',
        selector: 'Status',
        sortable: true,
        right: true,
    },
    {
        name: 'Last Updated',
        selector: 'updatedAt',
        sortable: true,
        sortFunction: sortUpdatedAt,
        right: true,
    },
    {
        name: '',
        selector: 'action',
        right: true
    },
]

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className='table-filter-wrapper'>
        <input className="table-filter" type="text" placeholder="Filter by Insured" aria-label="Search Input" value={filterText} onChange={onFilter} />
        <Button type="button" className={`clear-filter ${filterText.length ? 'btn-primary' : 'btn-grey'}`} onClick={onClear} disabled={!filterText.length}>Clear</Button>
    </div>
)

export const Reinsurance = () => {
    let externalRisks
    const [risks, setRisks] = useState<any[]>([])
    const [selectedRisk, setSeletedRisk] = useState<any>()
    const [showSTBMenu, setShowSTBMenu] = useState<boolean>(false)
    const [showRiskStatus, setShowRiskStatus] = useState<boolean>(false)
    const [externalRiskActivity, setExternalRiskActivity] = useState<any[]>([])
    const [filterText, setFilterText] = useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

    useEffect(() => {
        axios.get('https://sandbox.whitespace.co.uk/api/risks', externalBrokerAuth).then(response => {
            externalRisks = response.data
            whitespace(Query.GET, '/api/risks').then(response => {
                typeof(response) !== 'undefined' && processedRisks(response.data)
            })
        }).catch(error => {
            NotificationManager.error('Could not connect to external broker')
        })
    }, [])

    const getMRCforRisk = risk => {
        whitespace(Query.GET, `/api/risks/${risk.id}`).then(response => {
            typeof(response) !== 'undefined' && setSeletedRisk(response.data)
        })
    }

    const sendRiskToBroker = (risk, broker) => {
        const reinsuredLineItem = {
            elements: ['Whitesails Underwriting Inc'].map((text, i) => { return {index: i, text: text} }),
            mrcHeading: 'Reinsured',
            nameVariation: 'Reinsured',
            mrcSection: 'RiskDetails',
            originalHeading: 'REINSURED',
            originalSection: '',
            fragments: [],
            placeholders: [],
            subItems: []
        }
        risk.MRCContract.lineItems.splice(2, 0, reinsuredLineItem)
        risk.MRCContract.lineItems = risk.MRCContract.lineItems.map((lineItem, i) => {
            if(lineItem.mrcHeading === 'ContractType') {
                lineItem.elements = [{index:0, text: 'Facultative Reinsurance'}]
            }
            if(lineItem.mrcHeading === 'Insured') {
                lineItem.mrcHeading = 'OriginalInsured'
                lineItem.originalHeading = 'ORIGINAL INSURED',
                lineItem.nameVariation = 'OriginalInsured'
            }
            lineItem.index = i
            return lineItem
        })
        const { placingBrokerChannel, umr, contractType, insuranceType, insuredName } = risk.control
        const urlParams = `teamChannel=${broker.teamChannel}&UMR=${umr}&contractType=${contractType}&insuranceType=${insuranceType}&insuredName=${insuredName}`
        axios.post(
            `https://sandbox.whitespace.co.uk/api/risks/save?${urlParams}`,
            { 
                MRCContract: risk.MRCContract 
            },
            externalBrokerAuth
        )
        .then(success => {
            setShowSTBMenu(false)
            NotificationManager.success(`Successfully sent ${umr} to ${broker.name}`)
        })
        .catch(error => {
            NotificationManager.error('Could not send to broker')
        })
    }

    const getExternalRiskStatus = externalRiskId => {
        axios.get(`https://sandbox.whitespace.co.uk/api/activities/${externalRiskId}/full`, externalBrokerAuth).then(response => {
            setExternalRiskActivity(response.data.reverse())
            setShowRiskStatus(true)
        })
    }

    const riskStatusPopup = () => {
        if (showRiskStatus) return (
          <Modal show={showRiskStatus} onHide={() => setShowRiskStatus(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Latest Risk Activity at Broker</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {externalRiskActivity.map(activity => {
                  return (
                      <Row>
                          <Col className='line' sm={1}><div className='victoria'>•</div></Col>
                          <Col><b>{activity.activity}</b></Col>
                          <Col>{dayjs().to(dayjs(activity.createdAt).format(wsDatetimeFormat))}<br /><i>{activity.createdAt}</i></Col>
                      </Row>
                  )
              })}
            </Modal.Body>
          </Modal>
        )
    }

    const STBMenu = () => {
        if (selectedRisk) return (
          <Modal show={showSTBMenu} onHide={() => setShowSTBMenu(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Send Risk to Broker to Reinsure</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>Risk Summary</h4>
              <b>Risk Name</b> {selectedRisk.control.insuredName}
              <br />
              <b>UMR</b> {selectedRisk.control.umr}
              <br />
              <b>Status</b> {selectedRisk.control.status}
              <br />
              <h4>Select Broker</h4>
              Send to broker:
              <select>
                  {externalBrokers.map(broker => {
                      return (
                        <option value={broker.id}>{broker.name}</option>
                      )
                  })}
              </select>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => sendRiskToBroker(selectedRisk, externalBrokers[0])}>Request Reinsurance</Button>
            </Modal.Footer>
          </Modal>
        )
    }

    const processedRisks = risks => {
        setRisks(risks.map(risk => {
            risk.updatedAtUnix = dayjs(risk.updatedAt).unix()
            risk.updatedAt = dayjs().to(dayjs(risk.updatedAt).format(wsDatetimeFormat))
            try {
                const externalRiskId = externalRisks.filter(externalRisk => externalRisk.umr === risk.umr)[0].id
                risk.action = <Button onClick={() => { getExternalRiskStatus(externalRiskId) }}>View Reinsurance Request</Button>
            } catch {
                risk.action = <Button variant='outline-primary' onClick={() => { setShowSTBMenu(true); getMRCforRisk(risk) }}>Buy Reinsurance</Button>
            }
            return risk
        }))
    }

    const ExpandedRisk = ({ data }) => {
        return <Risk riskId={data.id} />
    } 

    const filteredRisks = risks.filter(risk => risk.InsuredName && risk.InsuredName.toLowerCase().includes(filterText.toLowerCase()))

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle)
            setFilterText('')
        }
        }

        return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    }, [filterText, resetPaginationToggle])

    return (
        <div className="Module-reinsurance">
        {!risks.length && <PageLoading />}
        <div className={`Fadein-wrapper ${risks.length && 'load'}`}>
            {STBMenu()}
            {riskStatusPopup()}
            {subHeaderComponentMemo}
            <DataTable
                columns={columns}
                data={filteredRisks}
                defaultSortField="updatedAtUnix"
                defaultSortAsc={false}
                expandableRows
                expandableRowsComponent={<ExpandedRisk data={risks} />}
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[10, 50, 100, 500]}
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                persistTableHead
            />
        </div>
        </div>
    )
}