/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import NoResults from '../assets/images/result_not_found.png'
import BeatLoader from 'react-spinners/BeatLoader'
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader'

export const PageLoading = () => {
    return <div className="Page-loading"><ClimbingBoxLoader color={'#999999'} /></div>
}

export const SubpageLoading = () => {
    return <div className="Subpage-loading"><BeatLoader color={'#999999'} /></div>
}

export const NoResultsFound = () => {
    return <div className="Page-loading">
        <h1>No Results Found</h1>
        <img className="Img-fullpage" src={NoResults} />
    </div>
}