export const ENVIRONMENTS = [
    { 
        name : 'DEV', 
        TENANTID : '0a0d0df1-e485-485d-9919-4d4980a42e50', 
        CLIENTID : '4c939aea-47bf-46c5-8d14-d9cb4a81982e',
        BASE : 'https://dev.whitespace.co.uk',
        API : 'https://dev.whitespace.co.uk/api'
    }, 
    { 
        name : 'TESS', 
        TENANTID : '0a0d0df1-e485-485d-9919-4d4980a42e50', 
        CLIENTID : '2fe378b8-fe90-4cd8-b5cb-ebcec10164d3',
        BASE : 'https://tess.whitespace.co.uk',
        API : 'https://tess.whitespace.co.uk/api'
    }, 
    { 
        name : 'TESS-ALT', 
        TENANTID : '0a0d0df1-e485-485d-9919-4d4980a42e50', 
        CLIENTID : '2fe378b8-fe90-4cd8-b5cb-ebcec10164d3',
        BASE : 'https://tess-alt.whitespace.co.uk',
        API : 'https://tess-alt.whitespace.co.uk/api'
    }, 
    { 
        name : 'TESSETA', 
        TENANTID : '0a0d0df1-e485-485d-9919-4d4980a42e50', 
        CLIENTID : '2fe378b8-fe90-4cd8-b5cb-ebcec10164d3',
        BASE : 'https://tesseta.whitespace.co.uk',
        API : 'https://tesseta.whitespace.co.uk/api'
    }, 
    { 
        name : 'TESSZETA', 
        TENANTID : '0a0d0df1-e485-485d-9919-4d4980a42e50', 
        CLIENTID : '2fe378b8-fe90-4cd8-b5cb-ebcec10164d3',
        BASE : 'https://tesszeta.whitespace.co.uk',
        API : 'https://tesszeta.whitespace.co.uk/api'
    }, 
    { 
        name : 'BETA', 
        TENANTID : 'df4d23bc-a423-46ca-9506-caae61238a8b', 
        CLIENTID : '80507d48-c4e1-412d-9c77-33c5b2a1b665',
        BASE : 'https://beta.whitespace.co.uk',
        API : 'https://beta.whitespace.co.uk/api' 
    }, 
    { 
        name : 'STAGING', 
        TENANTID : 'df4d23bc-a423-46ca-9506-caae61238a8b', 
        CLIENTID : '80507d48-c4e1-412d-9c77-33c5b2a1b665',
        BASE : 'https://staging.whitespace.co.uk',
        API : 'https://staging.whitespace.co.uk/api' 
    }, 
    { 
        name : 'SANDBOX', 
        TENANTID : 'df4d23bc-a423-46ca-9506-caae61238a8b', 
        CLIENTID : '80507d48-c4e1-412d-9c77-33c5b2a1b665',
        BASE : 'https://sandbox.whitespace.co.uk',
        API : 'https://sandbox.whitespace.co.uk/api' 
    }, 
    { 
        name : 'DEMO', 
        TENANTID : '0a0d0df1-e485-485d-9919-4d4980a42e50', 
        CLIENTID : '1cc0af85-d9de-457b-8b0d-fe9976cc50ec',
        BASE : 'https://demo.whitespace.co.uk',
        API : 'https://demo.whitespace.co.uk/api' 
    }, 
    { 
        name : 'PRODUCTION', 
        TENANTID : '422b43e1-2339-4507-b836-55d0b242910b', 
        CLIENTID : 'f41279b9-90fb-4062-ab90-b862a4083613',
        BASE : 'https://www.whitespaceplatform.com',
        API : 'https://www.whitespaceplatform.com/api'
    }, 
    { 
        name : 'SUPPORT', 
        TENANTID : '422b43e1-2339-4507-b836-55d0b242910b', 
        CLIENTID : 'f41279b9-90fb-4062-ab90-b862a4083613',
        BASE : 'https://support-prod.whitespace.co.uk',
        API : 'https://support-prod.whitespace.co.uk/api'
    }
]
