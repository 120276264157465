import React, { useEffect, useState } from 'react'
import { SubpageLoading } from './Loading'
import { whitespace, Query } from '../helpers/whitespace'

const defaultLineItems = [
    { name: 'UMR', mrcHeading: 'UMR' },
    { name: 'Insured', mrcHeading: 'Insured' },
    { name: 'Overseas Broker', mrcHeading: 'OverseasBroker' },
    { name: 'Period', mrcHeading: 'Period' },
    { name: 'Premium', mrcHeading: 'Premium' },
]

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Risk = ({ riskId, lineItems = defaultLineItems, searchString = '' }) => {
    const [riskLineItems, setRiskLineItems] = useState<any>()
    useEffect(() => {
        whitespace(Query.GET, `/api/risks/${riskId}/text`).then(risk => {
            setRiskLineItems(risk.data.lineItems)
        })
    }, [])

    const renderSection = lineItemObject => {
        const lineItem = riskLineItems.filter(risk => risk.mrcHeading === lineItemObject.mrcHeading)[0]
        if(!lineItem) return null
        return (
            <div className="row Risk-section d-flex">
                <div className="col title col-2">{lineItemObject.name}</div>
                <div className="col content col-9">{lineItem.text.map(line => {
                    return (<p>{line}</p>)
                })}</div>
            </div>
        )
    }

    const searchRisk = () => {
        const searchLower = searchString.toLowerCase();
        return riskLineItems.map(lineItem => {
            if(JSON.stringify(lineItem).toLowerCase().includes(searchLower)) {
                return (
                    <div className="row Risk-section d-flex">
                        <div className="col title col-2">{lineItem.originalHeading}</div>
                        <div className="col content col-9">{lineItem.text.map(line => {
                            const pos = line.toLowerCase().indexOf( searchLower );
                            if( pos >= 0 ) {
                                const parts : string[] = [];
                                parts.push( line.substr( 0, pos ) );
                                parts.push( line.substr( pos, searchString.length ));
                                parts.push( line.substr( pos + searchString.length ));
                                return ( <p>{parts[0]}<span className='highlighted'>{parts[1]}</span>{parts[2]}</p>)
                            } else {
                                return ( <p>{line}</p>)
                            }

                        })}</div>
                    </div>
                ) 
            }
        })
    }

    return (
        <>
        {!riskLineItems ? <SubpageLoading /> : 
        <>
        <div className="table Risk-wrapper">
            {searchString.length > 0 ? searchRisk() : lineItems.map(lineItem => renderSection(lineItem))}
        </div>
        </>
        }
        </>
    )
}
