import dotenv from 'dotenv'
import * as Msal from 'msal'
import dayjs from 'dayjs'
import { ENVIRONMENTS } from '../constants/environments'

dotenv.config()

enum CacheStorage {
  LocalStorage = 'localStorage',
  SessionStorage = 'sessionStorage'
}

// Config object to be passed to Msal on creation
export const getMsal = environment => {
    const msalConfig = {
        auth: {
            clientId: environment.CLIENTID,
            authority: 'https://login.microsoftonline.com/'  + environment.TENANTID,
            redirectUri: process.env.REACT_APP_REDIRECT_URI,
        },
        cache: {
            cacheLocation: CacheStorage.LocalStorage, // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        } as const,
    }
    const msal = new Msal.UserAgentApplication(msalConfig)
    return msal
}

export const isUserPermitted = email => {
    const permissibleUsers: string = process.env.REACT_APP_PERMITTED_USERS || ''
    return permissibleUsers.split(' ').filter(allowed => {
        return ((allowed === "*") || (allowed === email) || (allowed.split('@')[0] === '*' && allowed.split('@')[1] === email.split('@')[1])) ? true : false
    }).length > 0
}

export const isLoggedIn = () => {
    try {
        const session = JSON.parse(localStorage['session'])
        const userPermitted = isUserPermitted(session.account.idToken.email)
        const tokenStillValid = dayjs().isBefore(dayjs.unix(session.idToken.expiration))
        return userPermitted && tokenStillValid ? true : false
    } catch(e) {
        return false
    }
}

export const getSessionDetails = () => {
    const session = JSON.parse(localStorage['session'])
    if (typeof(session.idToken.rawIdToken) === 'undefined') {
        console.warn('Not signed in')
        logout()
    } else {
        return session
    }
}

export const getSessionToken = () => {
    const sessionDetails = getSessionDetails()
    return sessionDetails.idToken.rawIdToken
}

export const getBaseUrl = () => {
    const cluster = localStorage['cluster']
    const env: any = ENVIRONMENTS.filter(environment => cluster === environment.name).pop()
    return env.BASE
}

export const getApiRoot = (service: string): string => {
    return getBaseUrl() + '/' + service
}

export const logout = () => {
    localStorage.clear()
    window.location.reload()
}
