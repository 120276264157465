import React from 'react'
import axios from 'axios'
import { getBaseUrl, logout } from '../helpers/auth'

export const PlatformLink = ({ id }) => {
    return (
        <a href={`${getBaseUrl()}/p/${id}`} target="_blank">
            <button className="btn btn-outline-primary btn-sm">View in Whitespace ⤤</button>
        </a>
    )
}

export const DownloadSignedRiskPDF = ({ id, configuration, auth, title = 'Signed Risk', disabled = false, environment = 'sandbox' }) => {
    const generatePDF = () => {
        axios
        .post(`https://${environment}.whitespace.co.uk/export/pdf/${id}`, configuration, { 
            responseType: 'arraybuffer',
            headers: { 
                'Authorization': auth.headers['Authorization'],
                'Accept': '*/*',
                'Content-type': 'application/json, text/plain, */*',
            }
        })
        .then(response => {
            const blob = new Blob([response.data], {type: response.headers['content-type']})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = title
            link.click()
         })
        .catch(function (error) {
            console.error(error)
            return
            return (error.message === 'Network Error') ? 'Network Error' : logout()
        })
    }

    return (
        <button className="btn btn-outline-danger" disabled={disabled} onClick={() => generatePDF()}>Download PDF ↓</button>
    )
}