export const daffodil_cdr_json = `
{
    "cdrId": "1dcf9b07-3103-48a8-92c7-74fde3ca8410",
    "services": {
            "taxCalculator": {
              "status": "ERROR",
              "code": "0002",
              "errors": [
                {
                  "field": "dateContractEnteredInto",
                  "message": "dateContractEnteredInto cannot be null"
                }
              ]
            },
            "riskLocations": {
              "status": "PASS"
            },
            "licenceStatusCheck": {
              "status": "PASS"
            },
            "idv": {
              "status": "PENDING"
            },
            "sanctions": {
              "status": "ERROR",
              "code": "0002",
              "errors": [
                {
                  "field": "sanctionsOnCompanyRequest",
                  "message": "Please pass a valid entity type, we currently accept COMPANY."
                },
                {
                  "field": "entityIdentifier",
                  "message": "Please pass a valid company identifier. We currently accept 9 digit duns numbers."
                }
              ]
            },
            "clauseChecker": {
              "status": "FAIL",
              "message": "One or more automated clause checks were failed: 1_270001: Are any payment terms identified?,9_000505: Unless prohibited by reason of local or legal requirements, check that there is a suitable exclusion for nuclear, chemical, biological and radioactive (NCBR) material used as a weapon, e.g. NMA2962, NMA1191, LMA5288.,4_810001: Ensure that the Market Reform Contract includes the Regulatory Client Classification in the Fiscal and Regulatory section"
            },
            "reglicenceChecker": {
              "status": "FAIL",
              "message": "One or more automated regulatory checks were failed: 9_000358-CA: For Open Market, Direct Insurance contracts in Canada where the insured type is either Personal or Commercial, ensure that the contract contains either clause LSW1565c (English version) or clause LSW1565c-16 (French version). This is the agreed Lloyd's version of the Insurance Bureau of Canada's Code entitled 'Consumer Rights and Responsibilities'.,9_000385-CA: For Open Market, Direct Insurance contracts in British Columbia, Canada, ensure the contract contains clause LSW1815 to comply with the statutory conditions according to the British Columbia Insurance Act.,1_250027-CA: For Open Market, Direct Insurance contracts in Canada (and contracts where the global policyholder is a Canadian located insured), ensure the contract contains the LMA5028A service of suit clause (can be amended to show a name other than the Attorney in Fact, but only with the latter's consent)."
            }
          },
          "uniqueReference": "B9988202106161236",
          "brokerPolicyReference": "8202106161236",
          "insuranceContractType": "INSURANCE",
          "insuranceContractSubType": "INSURANCE",
          "methodOfPlacement": "OPEN_MARKET",
          "startOfInsurancePeriod": "2098-01-01T00:01:00Z",
          "endOfInsurancePeriod": "2099-01-01T00:01:00Z",
          "dateContractEnteredInto": "2050-03-02T10:30:22.000000022Z",
          "basisOfAuthorisation": "US_SURPLUS_LINES",
          "contractOriginalCurrency": "USD",
          "originalLanguageOfWordings": "EN",
          "contractPremiums": [
            {
              "type": "PREMIUM",
              "premiumCurrency": "USD",
              "premiumAmount": "999999999.00"
            }
          ],
          "contractCovers": [
            {
              "type": "DAMAGE",
              "coverPerils": [
                {
                  "type": "CYBER",
                  "inclusionExclusionIndicator": "EXCLUDED"
                },
                {
                  "type": "FIRE",
                  "inclusionExclusionIndicator": "INCLUDED"
                },
                {
                  "type": "FLOOD",
                  "inclusionExclusionIndicator": "INCLUDED"
                },
                {
                  "type": "EARTHQUAKE",
                  "inclusionExclusionIndicator": "INCLUDED"
                }
              ],
              "coverAmounts": [
                {
                  "type": "Synthetic",
                  "coverAmountCurrency": "USD",
                  "coverAmount": "999999999.00",
                  "coverAmountBasis": "limitCSLPerOccurrence",
                  "coverDeductibleOrExcessAmount": "999999999.00"
                }
              ],
              "classOfBusinessLloyds": "PROPERTY_COMMERCIAL_ALL_RISKS_FIXED"
            }
          ],
          "insuredItems": [
            {
              "type": "FIXED_PROPERTY",
              "insuredItemGroup": "PROPERTY",
              "insuredItemSubGroup": "PROPERTY",
              "sumInsured": "1111111.00",
              "insuredItemPhysicalAddress": {
                "address": {
                  "streetNoAndStreet": "1 Front Street",
                  "city": "Vancouver",
                  "zipCode": "10101",
                  "location": {
                    "countrySubDivisionCode": "CA-BC",
                    "countrySubDivisionName": "British Columbia",
                    "ISOCountryShortName": "CA"
                  }
                }
              }
            },
            {
                "type": "FIXED_PROPERTY",
                "insuredItemGroup": "PROPERTY",
                "insuredItemSubGroup": "PROPERTY",
                "sumInsured": "2222222.00",
                "insuredItemPhysicalAddress": {
                  "address": {
                    "streetNoAndStreet": "2 Front Street",
                    "city": "Vancouver",
                    "zipCode": "20202",
                    "location": {
                      "countrySubDivisionCode": "CA-BC",
                      "countrySubDivisionName": "British Columbia",
                      "ISOCountryShortName": "CA"
                    }
                  }
                }
              }
        ],
        "insured": [
          {
            "type": "COMMERCIAL",
            "party": {
              "type": "ORGANISATION",
              "name": "Broadspan PLC",
              "partyIdentifiers": [
                {
                  "type": "DUNS",
                  "id": "12345678"
                }
              ],
              "partyAddresses": [
                {
                  "streetNoAndStreet": "1 Main Street",
                  "city": "Des Moines",
                  "zipCode": "77668",
                  "location": {
                    "countrySubDivisionCode": "US-IA",
                    "countrySubDivisionName": "Iowa",
                    "ISOCountryShortName": "US"
                  }
                }
              ]
            },
            "regulatoryClassification": {
              "regulatoryClientClassification": "Commercial – Micro-enterprise",
              "usStateOfFiling": "New Jersey"
            }
          }
        ],
        "intermediaries": [
          {
            "type": "LLOYDS_BROKER",
            "party": {
              "name": "Gail Broker",
              "partyIdentifiers": [
                {
                  "type": "DUNS",
                  "id": "123987456"
                },
                {
                  "type": "LLOYDS",
                  "id": "8899"
                }
              ],
              "partyAddresses": [
                {
                  "streetNoAndStreet": "51 11th Avenue",
                  "city": "Newark",
                  "zipCode": "25678",
                  "location": {
                    "countrySubDivisionCode": "US-NJ",
                    "countrySubDivisionName": "New Jersey",
                    "ISOCountryShortName": "US"
                  }
                }
              ]
            },
            "surplusLinesBrokerDetails": {
              "surplusLinesBrokerNumber": "999999999"
            },
            "role": "PLACING_BROKER"
          },
          {
            "type": "RETAIL_BROKER",
            "party": {
              "type": "ORGANISATION",
              "name": "Wilson, Wilson and Woodrup Inc",
              "partyIdentifiers": [
                {
                  "type": "DUNS",
                  "id": "12345678"
                }
              ],
              "partyAddresses": [
                {
                  "streetNoAndStreet": "243 South Street",
                  "city": "Des Plus",
                  "zipCode": "77685",
                  "location": {
                    "countrySubDivisionCode": "US-IA",
                    "countrySubDivisionName": "Iowa",
                    "ISOCountryShortName": "US"
                  }
                }
              ]
            },
            "surplusLinesBrokerDetails": {
              "surplusLinesBrokerNumber": "999999999"
            },
            "role": "PRODUCING_BROKER"
          },
          {
            "type": "SURPLUS_LINES_BROKER",
            "party": {
              "type": "ORGANISATION",
              "name": "Trubshaws Inc",
              "partyIdentifiers": [
                {
                  "type": "DUNS",
                  "id": "77886655"
                }
              ],
              "partyAddresses": [
                {
                  "streetNoAndStreet": "51 11th Avenue",
                  "city": "Newark",
                  "zipCode": "25678",
                  "location": {
                    "countrySubDivisionCode": "US-NJ",
                    "countrySubDivisionName": "New Jersey",
                    "ISOCountryShortName": "US"
                  }
                }
              ]
            },
            "surplusLinesBrokerDetails": {
              "surplusLinesBrokerNumber": "9876543"
            },
            "role": "PRODUCING_BROKER"
          }
        ]
    
}`;