import React, { useState, useEffect } from 'react'
import { whitespace, Query } from '../../helpers/whitespace'
import { riskFilter } from '../../helpers/riskFilter'
import { RiskGrid } from '../../components/RiskGrid'
import { Modal, Col, Row, Button } from 'react-bootstrap'
import axios from 'axios'
import { PageLoading } from '../../components/Loading'
import { NotificationManager } from 'react-notifications'

export const GPMMaker = () => {
    const [fetchingData, setFetchingData] = useState(true)
    const [risks, setRisks] = useState<any[]>([])
    const [docID, setDocID] = useState( '' )
    const [showDialog, setShowDialog] = useState(false)
    const [xmrc, setXMRC] = useState<any>( null )
    // const [gpmType, setGPMType] = useState( 'wsp')
    // const [receiverType, setReceiverType] = useState( 'broker')
    // const [receiverName, setReceiverName] = useState( 'Receiver Name')
    // const [lloydsID, setLloydsID] = useState( '9999')
    // const [msg, setMessage] = useState( '')

    useEffect(() => {
        setFetchingData(true)
        whitespace(Query.GET, '/api/risks').then(response => {
            setFetchingData(false)
            setRisks( riskFilter.groupAllRisks( response?.data || [] ) )
        })
    }, [])

    const pickRisk = ( risk ) => {
        setDocID( risk.id )
        whitespace(Query.GET, `/api/risks/${risk.id}/getExtendedMRC`).then( response => {
            console.log( 'getExtendedMRC', response.data )
            setXMRC( response.data )
            setShowDialog( true )
        })
    }

    // const onChangeGPMType = (event) => {
    //     setGPMType( event.target.value )
    // }

    // const setOption = () => {
    //     console.log( 'setOption')
    // }
    // const createGPM = () => {
    //     console.log( 'Creating GPM')
    //     externalAPI(Query.POST, url, xmrc).then( response => {
    //         console.log( response.data )
    //     })
    // }

    const generateXML = () => {
        const url = 'https://www.whitespaceplatform.com/extract/gpm?gpmType=wsp&receiverType=broker&receiverName=Acme&lloydsID=1234'
        const filename = `${xmrc?.control?.insuredName || 'GPM'}_${xmrc?.control?.umr || 'GPM'}.xml.txt`
        axios.post(url, xmrc, { 
            responseType: 'arraybuffer',
            headers: { 
                'Accept': '*/*',
                'Content-type': 'application/json',
            }
        })
        .then(response => {
            console.log( response.data )
            const blob = new Blob([response.data], {type: response.headers['content-type']})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = filename
            link.click()
            setShowDialog(false)
         })
        .catch(function (error) {
            console.error(error)
            return
        })
    }

    const PopupDialog = () => {
        return (
          <Modal show={showDialog} onHide={() => setShowDialog(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Create GPM</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='DlgSubTitle'>Risk Name</div> {xmrc?.control?.insuredName || ''}
              <div className='DlgSubTitle'>UMR</div> {xmrc?.control?.umr || ''}
              <div className='DlgSubTitle'>IC</div> {docID}
              <div className='Above12' onClick={() => generateXML()}><Button>Create GPM</Button></div>
            </Modal.Body>
          </Modal>
        )
    }

    return (
        <>
        {fetchingData && <PageLoading />}
        {!fetchingData && <RiskGrid input={risks} pickFn={pickRisk} />}
        <PopupDialog />
        </>
    )
}