/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { whitespace, Query } from "../../helpers/whitespace";
import { Button } from "react-bootstrap";

import "./WholeMarket.css";

const WMExpanded = (props) => {
  return <>
    {
      props.data.teamNameAndMembers.map( (t) => 
        <>
          <div className="TeamDetail">
          <Highlight text={t} grail={props.grail} /> 
          </div>        
        </>
      )
    }
  </>
};

const Highlight = ( { text, grail } ) => {
  const MakeClassName = ( i : number ) => {
    return ( i % 2 ) ? "Highlight1" : "Highlight0";
  };
  if( !grail || !text.toLowerCase().includes( grail.toLowerCase() ) ) {
    return <>
      {text}
    </>
  }
  const rgx = RegExp( grail, "gi");
  const parts : string[] = [];
  let i = 0;
  for( ; ; ) {
    const part = rgx.exec( text );
    if( !part ) {
      break;
    }
    if( part.index > i ) {
      parts.push( text.substr( i, part.index - i ) );
    } else {
      parts.push( "");
    }
    parts.push( part[0] );
    i = part.index + part[0].length;
  }
  if( text.length > i ) {
    parts.push( text.substr( i, text.length - i ) );
  }
  console.log( parts );
  return <>
  {
    parts.map((p,i) => <span className={MakeClassName(i)}>{p}</span>)
  }
  </>
}

const CompanyRow = ( { row, grail }) => {
  return <>
    <Highlight text={row.companyName} grail={grail} /> | {row.numberOfTeamsInCompany} teams | {row.numberOfMembersInCompany} users
  </>
}

export const WholeMarket = ({ refresh }) => {
  const [data, setData] = useState<any[]>([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const membersColumnsBroker = [
    {
      name: "Brokers",
      allowRowEvents: true,
      width: "100%",
      selector: (row) =>
      CompanyRow( { row: row, grail: filterText } )
    },
  ];

  const membersColumnsUnderwriter = [
    {
      name: "Carriers",
      allowRowEvents: true,
      width: "100%",
      selector: (row) =>
      CompanyRow( { row: row, grail: filterText } )
    },
  ];

  useEffect(() => {
    whitespace(Query.GET, "/api/shared/corporate").then((response) => {
      setData(response.data);
    });
  }, [refresh]);

  const restructuredData = (role) =>
    data
      .filter((data) => data.role === role)
      .map(
        ({
          name: companyName,
          members,
          teams: teamsByRole,
          isOffPlatform,
          currentState,
        }) => {
          const teams = teamsByRole.map(({ name: teamName, teamId }) => {
            const teamMembers = members
              .map(({ name, teams }) => teams.includes(teamId) && name)
              .filter(Boolean);
            return {
              teamName,
              teamMembers,
            };
          }).filter( (t) => teamMatchesSearch( t, filterText ) );
          return {
            companyName,
            teamNameAndMembers: teams
              .map((m) => m.teamName + " : " + m.teamMembers.join(", ")),
            numberOfTeamsInCompany: teams.length,
            teams,
            numberOfMembersInCompany: members.filter( (m) => memberMatchesSearch(m.name, filterText)).length,
            isOffPlatform,
            currentState,
          };
        }
      )
      .sort((a, b) => a.companyName.localeCompare(b.companyName))
      .filter(
        (f) =>
          f.teams.length !== 0 &&
          f.isOffPlatform !== false &&
          f.numberOfMembersInCompany !== 0 &&
          f.currentState !== ("Draft" || "Suspended") &&
          companyMatchesSearch( f )
          // f.companyName.toLowerCase().includes(filterText.toLowerCase())
      );

  const isExpanded = (row) => row.defaultExpanded;
  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const companyMatchesSearch = ( f ) => {
    const grail = filterText.trim().toLocaleLowerCase();
    if( grail == "" )
      return true;
    if( f.companyName.toLowerCase().includes( grail ) )
      return true;
    if( f.teams.filter( (t) => teamMatchesSearch( t, grail ) ).length > 0 )
      return true;
    return false;
  }

  const teamMatchesSearch = ( t, grail ) => {
    if( t.teamName.toLowerCase().includes( grail ) )
      return true;
    if( t.teamMembers.filter( (m) => memberMatchesSearch(m, grail ) ).length > 0 )
      return true;
    return false;
  }

  const memberMatchesSearch = ( m, grail ) => {
    return m.toLowerCase().includes( grail );
  }

  const highlightGrail = ( str ) => {
    return str;
    // const grail = filterText.trim().toLocaleLowerCase();
    // if( !str.toLowerCase().includes( grail ) )
    //   return str;
    // return "**" + str + "**";
  }

  return (
    <>
      <div>
        <input
          className="input-field"
          id="search"
          type="text"
          placeholder="Filter By Company"
          aria-label="Search Input"
          onChange={(e) => setFilterText(e.target.value)}
          value={filterText}
        ></input>&nbsp;
        <Button type="button" onClick={handleClear}>Clear</Button>
      </div>
      <div className="data-table">
        <div className="table-wrapper">
          <DataTable
            columns={membersColumnsBroker}
            data={restructuredData("broker")}
            expandableRows
            expandableRowExpanded={isExpanded}
            expandableRowsComponent={
              <WMExpanded
                dataFilteredByBroker={restructuredData("broker")}
                grail={filterText}
              />
            }
            selectableRowsComponentProps={(row) => ({ row })}
            pagination
            paginationPerPage={15}
            paginationRowsPerPageOptions={[15, 50, 100, 500]}
            className="RiskGridSingleLine"
          />
        </div>
        <div className="table-wrapper">
          <DataTable
            columns={membersColumnsUnderwriter}
            data={restructuredData("underwriter")}
            expandableRows
            expandableRowExpanded={isExpanded}
            expandableRowsComponent={
              <WMExpanded
                dataFilteredByUnderwriter={restructuredData("underwriter")}
                grail={filterText}
              />
            }
            selectableRowsComponentProps={(row) => ({ row })}
            pagination
            paginationPerPage={15}
            paginationRowsPerPageOptions={[15, 50, 100, 500]}
            className="RiskGridSingleLine"
          />
        </div>
      </div>
    </>
  );
};
