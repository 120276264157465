import React, { useState, useEffect } from 'react'
import { PageLoading, NoResultsFound } from '../../components/Loading'
import { PlatformLink } from '../../components/PlatformLink'
import { whitespace, Query } from '../../helpers/whitespace'
import DataTable from 'react-data-table-component'
import { Form, Button, Badge, Row, Col } from 'react-bootstrap'
import { Risk } from '../../components/Risk'
import dayjs from 'dayjs'
import BeatLoader from 'react-spinners/BeatLoader'
import relativeTime from 'dayjs/plugin/relativeTime'
import { NotificationManager } from 'react-notifications'
import { sortUpdatedAt } from '../../helpers/processes'
import './PolicySearch.css'

dayjs.extend(relativeTime)
const wsDatetimeFormat = 'YYYY-MM-DD HH:mm:ss'

const columns = [
    {
        name: 'Insured',
        selector: 'InsuredName',
        sortable: true,
    },
    {
        name: 'UMR',
        selector: 'umr',
        sortable: true
    },
    // {
    //     name: 'Contract Type',
    //     selector: 'ContractType',
    //     sortable: true,
    //     right: true,
    // },
    // {
    //     name: 'Status',
    //     selector: 'Status',
    //     sortable: true
    // },
    {
        name: 'Status and ID',
        selector: 'statusAndID',
        sortable: true
    },
    {
        name: 'Last Updated',
        selector: 'updatedAt',
        sortable: true,
        sortFunction: sortUpdatedAt,
        right: true,
    },
    {
        name: 'Search Result',
        selector: 'stringFoundBadge',
        sortable: true,
        right: true
    },
    {
        name: '',
        selector: 'WSLink',
        right: true
    },
]

const status = {
    searching: <BeatLoader />,
    notfound: <h4><Badge variant="success">Not Found</Badge></h4>,
    found: <h4><Badge variant="warning">String Found</Badge></h4>,
}

const RiskScanner = ({ id, searchString }) => {
    const [found, setFound] = useState(status.searching)
    whitespace(Query.GET, `/api/risks/${id}/text`).then(risk => {
        try {
            setFound(JSON.stringify(risk).toLowerCase().includes(searchString.toLowerCase()) ? status.found : status.notfound)
        } catch (e) {
            setFound(status.notfound)
        }
    })
    return found
}

const StatusAndWrappedID = ( { id, status } ) => {
    let prefix = id, suffix = "";
    const pos = id.indexOf( "::");
    if( pos >= 0 ) {
        prefix = id.substr( 0, pos );
        suffix = id.substr( pos );
    }
    return (
        <>
        <div>{status}</div>
        <div className='LightGray'>{id}</div>
        <div className='LightGray'>{suffix}</div>
        </>
    )
}

export const PolicySearch = () => {
    const [searching, setSearching] = useState(false)
    const [searchSince, setSearchSince] = useState(1)
    const [searchString, setSearchString] = useState('AMBANT')
    const [loaded, setLoaded] = useState(false)
    const [risks, setRisks] = useState<any[]>([])
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

    useEffect(() => {
        getAllRisks()
    }, [])

    const getAllRisks = () => {
        whitespace(Query.GET, '/api/risks').then(response => {
            setRisks([])
            response.status === 200 && processRisks(response.data)
        })
    }

    const processRisks = risks => {
        const processedRisks = risks.map(risk => {
            risk.InsuredName = risk.InsuredName.length > 30 ? risk.InsuredName.substr(0,25) + '...' : risk.InsuredName
            risk.ContractType = risk.ContractType.length > 20 ? risk.ContractType.substr(0,25) + '...' : risk.ContractType
            risk.createdAt = dayjs().to(dayjs(risk.createdAt).format(wsDatetimeFormat))
            risk.updatedAtUnix = dayjs(risk.updatedAt).unix()
            risk.updatedAt = dayjs().to(dayjs(risk.updatedAt).format(wsDatetimeFormat))
            risk.statusAndID = <StatusAndWrappedID id={risk.id} status={risk.Status} />
            risk.stringFoundBadge = <RiskScanner id={risk.id} searchString={searchString} />
            risk.WSLink = <PlatformLink id={risk.id} />
            return risk
        }).filter(risk => {
            return risk.updatedAtUnix > dayjs().subtract(searchSince, 'day').unix()
        })
        setSearching(false)
        setRisks(processedRisks)
        setLoaded(true)
    }

    // const getPolicies = ids => {
    //     Promise.all(ids.slice(2,40).map(id => whitespace(Query.GET, `/api/risks/${id}/text`))).then(policies => {
    //         const risks = policies.map((policy: any) => parseLineItems(policy.data.lineItems))
    //         console.log(risks)
    //         setRisks(risks)
    //         setLoaded(true)
    //     })
    // }

    // const parseLineItems = lineItems => {
    //     try {
    //         return {
    //             insured: lineItems.filter(lineItem => lineItem.mrcHeading === 'Insured')[0].text[0],
    //         }
    //     } catch(e) {console.log(e)}
    // }

    const ExpandedRisk = ({ data }) => {
        return <Risk riskId={data.id} searchString={searchString} />
    } 

    const search = event => {
        event.preventDefault()
        if(searchString.length < 3) {
            return NotificationManager.error('Search string must contain 3 characters or more')
        }
        getAllRisks()
        setSearching(true)
    }

    return (
        <>
        {!loaded && <PageLoading />}
        <div className={`Fadein-wrapper ${loaded && 'load'}`}>
            <div className="Search-options">
                <Form className="Search-form" onSubmit={(event) => search(event)}>
                    <Form.Group as={Row} controlId="searchFor">
                        <Form.Label column sm="2">Search for</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" placeholder="word or phrase" defaultValue={searchString} onKeyUp={(event) => setSearchString(event.target.value) } />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formBasicRangeCustom">
                        <Form.Label column sm="2">In last {searchSince} days</Form.Label>
                        <Col sm="10">
                            <Form.Control type="range" custom min={1} max={10} value={searchSince} onChange={(event) => setSearchSince(+event.target.value)} />
                        </Col>
                    </Form.Group>
                    {searching ? <BeatLoader /> : <Button type="submit" className="submit-btn">Search</Button>}
                </Form>
            </div>
            <DataTable
                columns={columns}
                data={risks}
                expandableRows
                defaultSortField="updatedAtUnix"
                defaultSortAsc={false}
                expandableRowsComponent={<ExpandedRisk data={risks} />}
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[10, 50, 100, 500]}
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                persistTableHead
            />
        </div>
        </>
    )
}