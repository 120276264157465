// https://www.convertonline.io/convert/json-to-js
export const ExamplePayload = {
uniqueReference:'123',
brokerPolicyReference:'brokerPolicyRef',
insuranceContractType:'INSURANCE',
insuranceContractSubType:'INSURANCE',
methodOfPlacement:'OPEN_MARKET',
startOfInsurancePeriod:'2050-03-02T10:30:22.000000022Z',
endOfInsurancePeriod:'2090-03-02T10:30:22.000000022Z',
dateContractIssued:'2051-03-02T10:30:22.000000022Z',
basisOfAuthorisation:'CANADIAN_AIF',
contractOriginalCurrency:'USD',
originalLanguageOfWordings:'originalLanguageOfWordings',
choiceOfLawAndJurisdiction:{
country:{
location:{
countrySubDivisionCode:'CA-AB',
countrySubDivisionName:'CA',
ISOCountryShortName:'CA'}}},
apportionedTaxBasisMethod:'FIXED',
policyHolder:{
party:{
type:'ORGANISATION',
name:'party A',
partyIdentifiers:[{
type:'id',
id:'1234'}],
partyAddresses:[{
streetNoAndStreet:'123',
city:'London',
county:'Greater London',
zipCode:'123456',
location:{
countrySubDivisionCode:'CA-AB',
countrySubDivisionName:'CA',
ISOCountryShortName:'CA'}}]}},
intermediaries:[{
type:'DISTRIBUTOR',
party:{
type:'ORGANISATION',
name:'party A',
partyIdentifiers:[{
type:'id',
id:'1234'}],
partyAddresses:[{
streetNoAndStreet:'7thAve',
city:'Calgary',
county:'Alberta',
zipCode:'T2P0W4',
location:{
countrySubDivisionCode:'CA-AB',
countrySubDivisionName:'CA',
ISOCountryShortName:'CA'}}]},
surplusLinesBrokerDetails:{
surplusLinesBrokerNumber:'12345678',
surplusLinesTransactionNumber:'12345678901Aa-'},
role:'ORIGINAL_PRODUCING_BROKER'}],
insured:[{
description:'desc',
type:'COMMERCIAL',
party:{
type:'COMPANY',
name:'party A',
partyIdentifiers:[{
type:'duns',
id:'050957364'}],
partyAddresses:[{
streetNoAndStreet:'7thAve',
city:'Calgary',
county:'Alberta',
zipCode:'T2P0W4',
location:{
countrySubDivisionCode:'CA-AB',
countrySubDivisionName:'CA',
ISOCountryShortName:'CA'}}]},
industrialSectorDescription:'abc',
sanctionsCheckResult:{
sanctionResultTimeStamp:'1622549056650',
sanctionStatus:'CLEAR',
sanctionProvider:['SANCTIONS_A']},
revenue:10,
regulatoryClassification:{
regulatoryClientClassification:'COMMERCIAL_MICRO_ENTERPRISE',
usStateOfFiling:'Texas'},
insuredItemSubGroup:null}],
insuredItems:[{
type:'FIXED_PROPERTY',
insuredItemGroup:'PROPERTY',
insuredItemSubGroup:'PROPERTY',
sumInsured:10,
insuredItemPhysicalAddress:{
address:{
streetNoAndStreet:'7thAve',
city:'Calgary',
county:'Alberta',
zipCode:'T2P0W4',
location:{
countrySubDivisionCode:'CA-AB',
countrySubDivisionName:'British Columbia',
ISOCountryShortName:'CA'}}}}],
contractParticipations:[{
participantGroupType:'LLOYDS',
orderHereon:10,
basisOfSignedLines:'PERCENTAGE_OF_ORDER',
basisOfWrittenLines:'PERCENTAGE_OF_ORDER',
participants:[{
insurer:{
type:'ORGANISATION',
name:'party A',
partyIdentifiers:[{
type:'id',
id:'1234'}],
partyAddresses:[{
streetNoAndStreet:'123',
city:'London',
county:'Greater London',
zipCode:'123456',
location:{
countrySubDivisionCode:'CA-AB',
countrySubDivisionName:'CA',
ISOCountryShortName:'CA'}}]},
carrierPolicyReference:'abc',
signedLinePercentage:10,
carrierLeadIndicator:true,
writtenLinePercentage:10}]}],
contractSections:[{
sectionParticipants:[{
participantGroupType:'LLOYDS',
orderHereon:10,
basisOfSignedLines:'PERCENTAGE_OF_ORDER',
basisOfWrittenLines:'PERCENTAGE_OF_ORDER',
participants:[{
insurer:{
type:'ORGANISATION',
name:'party A',
partyIdentifiers:[{
type:'id',
id:'1234'}],
partyAddresses:[{
streetNoAndStreet:'123',
city:'London',
county:'Greater London',
zipCode:'123456',
location:{
countrySubDivisionCode:'CA-AB',
countrySubDivisionName:'CA',
ISOCountryShortName:'CA'}}]},
carrierPolicyReference:'abc',
signedLinePercentage:10,
carrierLeadIndicator:true,
writtenLinePercentage:10}]}],
sectionCovers:[{
description:'description',
type:'DAMAGE',
coverPerils:[{
description:'description',
type:'LIGHTNING',
inclusionExclusionIndicator:'INCLUDED'}],
coverAmounts:[{
type:'type',
coverAmountCurrency:'USD',
coverAmount:10,
coverAmountBasis:'basis',
coverDeductibleOrExcessAmount:10}],
classOfBusinessLloyds:'PROPERTY_COMMERCIAL_MOVEABLE'}],
sectionPremiums:{
type:null,
premiumCurrency:'CAD',
rateOfExchange:10,
premiumAmount:10,
premiumDiscountPercentage:1},
sectionPremiumSchedules:[{
instalmentFrequency:'freq',
premiumInstalments:[{
instalmentNumber:123,
instalmentPercentage:111,
instalmentAmount:10,
instalmentDueDate:'2051-03-02T10:30:22.000000022Z'}],
paymentSettlementTerms:'30 days',
settlementDueDate:null}],
sectionAcquisitionCosts:[{
type:null,
deductionPercentage:10,
deductionAmount:1,
deductionBasis:'test'}],
sectionTaxCalculations:[{
description:'desc',
taxType:'TAX',
taxAdministeredBy:'admin',
taxAmount:0.04,
taxCode:'CA_AB_PPT',
taxApplicationType:'FIXED',
taxBasis:'GROSS_WRITTEN_PREMIUM',
taxPayableBy:'INSURED',
taxRate:10,
taxRiskLocation:{
countrySubDivisionCode:'GB-GB',
countrySubDivisionName:'CA',
ISOCountryShortName:'CA'},
taxSettlementCurrency:'USD',
taxAuthority:'TAX_AUTHORITY',
taxClass:'TAX_CLASS',
taxAuthorityLocation:'TAX_AUTHORITY_ALLOCATION'}],
sectionClauses:[{
type:'type',
referenceNumber:'123',
titleDescription:'title',
clauseStatus:'ACTIVE'}]}],
contractCovers:[{
description:'description',
type:'DAMAGE',
coverPerils:[{
description:'description',
type:'CYBER',
inclusionExclusionIndicator:'EXCLUDED'},
{
description:'description',
type:'NCBR',
inclusionExclusionIndicator:'EXCLUDED'},
{
description:'description',
type:'WAR',
inclusionExclusionIndicator:'EXCLUDED'},
{
description:'description',
type:'WAR',
inclusionExclusionIndicator:'INCLUDED'}],
coverAmounts:[{
type:'type',
coverAmountCurrency:'USD',
coverAmount:10,
coverAmountBasis:'basis',
coverDeductibleOrExcessAmount:10}],
classOfBusinessLloyds:'PROPERTY_COMMERCIAL_MOVEABLE'}],
contractPremiums:[{
type:null,
premiumCurrency:'CAD',
rateOfExchange:10,
premiumAmount:10,
premiumDiscountPercentage:1}],
contractPremiumSchedules:[{
instalmentFrequency:'freq',
premiumInstalments:[{
instalmentNumber:123,
instalmentPercentage:111,
instalmentAmount:10,
instalmentDueDate:'2051-03-02T10:30:22.000000022Z'}],
paymentSettlementTerms:'30 days',
settlementDueDate:null}],
contractAcquisitionCosts:[{
type:null,
deductionPercentage:10,
deductionAmount:1,
deductionBasis:'test'}],
contractTaxCalculations:[{
description:'desc',
taxType:'TAX',
taxAdministeredBy:'Insurer - Lloyd\'s',
taxAmount:0.4,
taxCode:'CA_AB_PPT',
taxApplicationType:'FIXED',
taxBasis:'GROSS_WRITTEN_PREMIUM',
taxPayableBy:'INSURER',
taxRate:10,
taxRiskLocation:{
countrySubDivisionCode:'GB-GB',
countrySubDivisionName:'CA',
ISOCountryShortName:'CA'},
taxSettlementCurrency:'USD',
taxAuthority:'TAX_AUTHORITY',
taxClass:'CA_AB_PPT',
taxAuthorityLocation:'TAX_AUTHORITY_ALLOCATION'}],
clauses:[{
type:'type',
referenceNumber:'LMA3333',
titleDescription:'title',
clauseStatus:'ACTIVE'},
{
type:'type',
referenceNumber:'NMA0466',
titleDescription:'title',
clauseStatus:'ACTIVE'},
{
type:'type',
referenceNumber:'NMA0464',
titleDescription:'title',
clauseStatus:'ACTIVE'},
{
type:'type',
referenceNumber:'NMA2918',
titleDescription:'title',
clauseStatus:'ACTIVE'},
{
type:'type',
referenceNumber:'LMA5266A',
titleDescription:'title',
clauseStatus:'ACTIVE'},
{
type:'type',
referenceNumber:'NMA1256',
titleDescription:'title',
clauseStatus:'ACTIVE'},
{
type:'type',
referenceNumber:'LMA5401',
titleDescription:'title',
clauseStatus:'ACTIVE'},
{
type:'type',
referenceNumber:'LSW1814',
titleDescription:'title',
clauseStatus:'ACTIVE'},
{
type:'type',
referenceNumber:'LSW1565C-16',
titleDescription:'title',
clauseStatus:'ACTIVE'},
{
type:'type',
referenceNumber:'LMA5028A',
titleDescription:'title',
clauseStatus:'ACTIVE'},
{
type:'type',
referenceNumber:'LMA5180',
titleDescription:'title',
clauseStatus:'ACTIVE'},
{
type:'type',
referenceNumber:'LSW1815',
titleDescription:'title',
clauseStatus:'ACTIVE'}],
filCode:'CDA1',
regulatoryRiskLocationResult:[{
id:'id',
riskLocationStatus:'test',
riskLocation:{
countrySubDivisionCode:'CA-AB',
countrySubDivisionName:'CA',
ISOCountryShortName:'CA'},
riskLocationGuidance:'test',
licenseResult:{
licenseStatus:'LICENSED',
licenseGuidance:'guidance'},
determiningFactor:'Factor'}],
contractConfidenceCheckResult:{
contractConfidenceCheckDescription:'contractConfidenceCheckDescription',
contractConfidenceCheckId:'contractConfidenceCheckId',
contractConfidenceCheckResult:'contractConfidenceCheckResult'},
regulatoryCheckResult:{
regulatoryCheckId:'id',
regulatoryCheckDescription:'desc',
regulatoryCheckResult:true},
underWritingYear:2021,
solvencyIILineOfBusiness:'surplus',
dateContractEnteredInto:'2050-03-02T10:30:22.000000022Z'
}