import { Modal } from "react-bootstrap";

export const MemberPopup = ({
  selectedMember,
  setSelectedMember,
  showMemberPopup,
}) => {
  const Pill = ({ value, highlight }) => {
    return <div className={highlight ? "Pill" : "PillDisabled"}>{value}</div>;
  };

  return (
    <Modal
      size="lg"
      show={showMemberPopup}
      onHide={() => setSelectedMember(null)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{selectedMember.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="DlgSubTitle">Email</div>
        <div>{selectedMember.email}</div>
        <div className="DlgSubTitle">Current State</div>
        <div>{selectedMember.currentState}</div>
        <div className="DlgSubTitle">{selectedMember.numberOfTeams ? selectedMember.numberOfTeams : selectedMember.teams.length} team(s)</div>
        <div>{selectedMember.teams}</div>
        <div className="DlgSubTitle">Attributes</div>
        <div>
          <Pill
            highlight={selectedMember.allowReporting}
            value="Reporting"
          ></Pill>
          <Pill highlight={selectedMember.isAdmin} value="Admin"></Pill>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
