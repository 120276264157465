import React, { useState, useEffect } from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications'
import axios, { AxiosRequestConfig } from 'axios'
import { whitespace, Query } from '../../helpers/whitespace'
import { getBaseUrl, getSessionToken, logout } from '../../helpers/auth'
import { MUID } from '../../App'

export const MIReport = () => {
    const [layout, setLayout] = useState('tall')
    const [output, setOutput] = useState('xlsx')
    const [filename, setFilename] = useState( 'MIReport')
    const onChangeLayout = (event) => {
        setLayout( event.target.value )
    }
    const onChangeOutput = (event) => {
        setOutput( event.target.value )
    }
    const onChangeFilename = (event)  => {
        setFilename( event.target.value )
    }
    // const runReport = () => {
    //     const endpoint = `/api/mireport?layout=${layout}`
    //     whitespace(Query.BYTEARRAY, endpoint).then(response => {
    //         console.log( response.data );
    //         const blob = new Blob([response.data], {type: response.headers['content-type']})
    //         const link = document.createElement('a')
    //         link.href = URL.createObjectURL(blob)
    //         link.download = filename
    //         link.click()
    //      })
    //     .catch(function (error) {
    //         NotificationManager.error( `Error calling ${endpoint} ${error}`);
    //         console.error(error)
    //         return
    //     })
    // }

    const runReport = () => {
        const url = getBaseUrl() + `/api/mireport?layout=${layout}&format=${output}`
        console.log( url );
        console.log( `Bearer ${getSessionToken()}` );
        console.log( `MUID: ${MUID}` )
        axios.get(url, { 
            responseType: 'arraybuffer',
            headers: { 
                'Accept': '*/*',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${getSessionToken()}`,
                'UserID': MUID
            }
        })
        .then(response => {
            console.log( response.data )
            const blob = new Blob([response.data], {type: response.headers['content-type']})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `${filename}.${output}`;
            link.click()
         })
        .catch(function (error) {
            NotificationManager.error( `Error calling ${url} ${error}`);
            console.error(error)
            return
        })
    }

    return (
        <>
        <Container>
        <Row onChange={onChangeLayout}>
            <Col xs={2}>Layout</Col>
            <Col xs={2}><label><input type="radio" value="tall" name="layout" checked={layout === "tall"} /> Tall</label></Col>
            <Col xs={2}><label><input type="radio" value="wide" name="layout" checked={layout === "wide"}  /> Wide</label></Col>
        </Row>
        <Row onChange={onChangeOutput}>
            <Col xs={2}>Output</Col>
            <Col xs={2}><label><input type="radio" value="csv" name="output" checked={output === "csv"} /> CSV</label></Col>
            <Col xs={2}><label><input type="radio" value="xlsx" name="output" checked={output === "xlsx"}/> XLSX</label></Col>
        </Row>
        <Row>
            <Col xs={2}>Filename</Col>
            <Col xs={2}><input onChange={onChangeFilename} type="text" value={filename}></input></Col>
        </Row>
        <Row className='Above12'>
             <Col xs={2}>&nbsp;</Col>
             <Col xs={10}><Button onClick={runReport}>Run</Button></Col>
        </Row>
        <Row className='Above12'>
             <Col xs={2}>&nbsp;</Col>
             <Col xs={10}><span className="XSmallText LightGray">Version 1.0.2 4 October 2021 13:02</span></Col>
        </Row>
        </Container>
        </>
    )
}
